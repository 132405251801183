import React, { memo, useRef } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Translate from "../../../translation/utils/translateFunction";
import { Formik } from "formik";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";

const ModalFormikForm = ({
  show,
  title,
  onOk,
  onCancel,
  btnLabel,
  placeholder,
  schema,
  InitialValues,
}) => {
  const formikRef = useRef(false);

  const onSubmit = (formikValues) => {
    onOk(formikValues);
  };

  return (
    <Modal
      show={show}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      centered
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <Formik
        initialValues={InitialValues}
        innerRef={formikRef}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="taskHeading">{Translate("common:alert")}</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>{title}</Form.Label>
                  <FormikTextField
                    name="rejectedReason"
                    placeholder={placeholder}
                    autoComplete={"off"}
                    multiline
                    noOfRows={3}
                    maxLength={250}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {btnLabel}
                </button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default memo(ModalFormikForm);
