/* eslint-disable no-unused-vars */
// Import all components here
// import React, { Component } from 'react'
import AboutUs from "./Aboutus";
import EmployeeActivityLogs from "./ActivityLogs/Employee";
import GmActivityLogs from "./ActivityLogs/Gm";
import ManagerActivityLogs from "./ActivityLogs/Manager";
import OwnerActivityLogs from "./ActivityLogs/Owner";
import PaActivityLogs from "./ActivityLogs/Pa";
import VendorActivityLogs from "./ActivityLogs/Vendor";
import AddTask from "./AddTask";
import AddCommentListing from "./AddTask/Components";
//import TaskReminder from "./AddTask/Components/TaskReminder";
import NotFound from "./Authentication/404";
import InternalServer from "./Authentication/500";
import ForgotPassword from "./Authentication/forgotpassword";
import Signup from "./Authentication/signup";
import AddCompany from "./Company/AddCompany";
import EditCompany from "./Company/EditCompany";
import ContactRepository from "./ContactRepository";
import ContactRepoPrivateListing from "./ContactRepository/PrivateListing";
import AccessLogs from "./ContactRepository/PrivateListing/AccessLogs";
import AddNewContact from "./ContactRepository/PrivateListing/AddNewContact";
import EditContact from "./ContactRepository/PrivateListing/EditContact";
import SelectMember from "./ContactRepository/PrivateListing/SelectMember";
import ViewBusinessCard from "./ContactRepository/PrivateListing/ViewBusinessCard";
import PublicListContactRepo from "./ContactRepository/PublicListing";
import PublicListAccessLogs from "./ContactRepository/PublicListing/AccessLogs";
import PublicBusinessCard from "./ContactRepository/PublicListing/BusinessCard";
import Dashboard from "./Dashboard/Dashboard";
import MyDocuments from "./DocumentRepository/MyDocuments";
import AccessLogsMyDocument from "./DocumentRepository/MyDocuments/AccessLogs";
import AddMyDocument from "./DocumentRepository/MyDocuments/AddMyDocument";
import EditMyDocument from "./DocumentRepository/MyDocuments/EditMyDocument";
import ViewMyDocument from "./DocumentRepository/MyDocuments/ViewMyDocument";
import SharedDocuments from "./DocumentRepository/SharedDocuments";
import AccessLogsSharedDocument from "./DocumentRepository/SharedDocuments/AccessLogs";
import ViewSharedDocument from "./DocumentRepository/SharedDocuments/ViewDocument";
import GetForms from "./FormikForm";
import RejectedTaskListing from "./ManageTask/RejectedTask";
import RelatedTaskListing from "./ManageTask/RelatedTask";
import ReportedByMeTaskListing from "./ManageTask/ReportedByMeTask";
import SelfAssignedTaskListing from "./ManageTask/SelfAssignedTask";
import MessageScreen from "./Message";
import NotificationListing from "./Notifications";
import Privacy from "./Privacy";
import Profile from "./Profile/Profile";
import ViewCompany from "./Profile/ViewCompany";
import Renewals from "./Renewals";
import AddRenewals from "./Renewals/AddRenewals";
import EditRenewals from "./Renewals/EditRenewals";
import ViewRenewals from "./Renewals/ViewRenewals";
import SubtaskListing from "./Task/Subtask";
import AddSubTask from "./Task/Subtask/AddSubtask";
import EditSubtask from "./Task/Subtask/EditSubtask";
import ViewSubtask from "./Task/Subtask/ViewSubtask";
import TermsCondition from "./TermsCondition";
import VendorListing from "./VendorsSuppliers";
import AddVendor from "./VendorsSuppliers/AddVendor";
import EditVendor from "./VendorsSuppliers/EditVendor";
import ViewVendor from "./VendorsSuppliers/ViewVendor";
import VoiceNotesListing from "./VoiceNotes/MyVoiceNotesListing";
import SetReminder from "./VoiceNotes/SetReminder";
import SharedWithMe from "./VoiceNotes/SharedWithMe";
import AccessLogsVoiceNote from "./VoiceNotes/SharedWithMe/AccessLogs";
import EmployeeListing from "./staff/Employee";
import AddEmployee from "./staff/Employee/Components/AddEmployee";
import GeneralManagerListing from "./staff/GeneralManager";
import AddGeneralManager from "./staff/GeneralManager/Components/AddGeneralManager";
import ViewGeneralManager from "./staff/GeneralManager/Components/ViewGeneralManager";
import ManagerListing from "./staff/Manager";
import AddManager from "./staff/Manager/Components/AddManager";
import ViewManager from "./staff/Manager/Components/ViewManager";
import OwnerListing from "./staff/Owner";
import AddOwner from "./staff/Owner/Components/AddOwner";
import ViewOwner from "./staff/Owner/Components/ViewOwner";
import PersonalAssistantListing from "./staff/PersonalAssistant";
import AddPersonalAssistant from "./staff/PersonalAssistant/AddPersonalAssistant";
import EditPersonalAssistant from "./staff/PersonalAssistant/EditPersonalAssistant";
import ViewPersonalAssistant from "./staff/PersonalAssistant/ViewPersonalAssistant";

import ChangePassword from "./Authentication/ChangePassword";
import Calendar from "./Calendar";
import CreateEvent from "./Calendar/CreateEvent";
// import ViewEmailDetails from "./HRMS/InboxModule/ViewEmail";
// import SentMailList from "./HRMS/InboxModule/SentEmailList";
import EditEvent from "./Calendar/EditEvent";
import ViewEvent from "./Calendar/ViewEvent";
// import ViewEvent from "./Calendar/ViewEvent";
import PrivateContactsSelectedMember from "./ContactRepository/PrivateListing/PrivateContactsSelectedMember";
import ViewBusinessCardSelectMember from "./ContactRepository/PrivateListing/SelectMember/ViewSelectMember";
import BusinessCardList from "./ContactRepository/PublicListing/BusinessCardList";
import SharedList from "./ContactRepository/SharedList";
import ViewCardSharedList from "./ContactRepository/SharedList/ViewBusinessCard";
import InboxList from "./InboxModule/InboxList";
import SentMailList from "./InboxModule/SentEmailList";
import ViewEmailDetails from "./InboxModule/ViewEmail";
import AssignedByPATaskListing from "./ManageTask/AssignedByPA";
import AssignedToMeTaskListing from "./ManageTask/AssignedToMe";
import ManageTask from "./ManageTask/ManageTask";
import PendingApprovalTaskListing from "./ManageTask/PendingApprovalTask";
import PinnedTaskListing from "./ManageTask/PinnedTask";
import ReAllocationRequestListing from "./ManageTask/ReAllocationRequestTask";
import ReportedByPA from "./ManageTask/ReportedByPA";
import ViewNotification from "./Notifications/ViewNotification";
import CompanyStructure from "./OrganizationStructure/CompanyStructure";
import ReportingStructure from "./OrganizationStructure/ReportingStructure";
import AttendanceReport from "./Reports/AttendanceReport";
import AttendanceReportDeatil from "./Reports/AttendanceReport/ViewAttendanceReport";
import PerformanceReport from "./Reports/PerformanceList";
import PerformanceDetails from "./Reports/PerformanceList/Components/PerformanceDetails";
import StaffReports from "./Reports/StaffReports";
import ViewStaffReport from "./Reports/StaffReports/ViewStaffReport";
import TaskReports from "./Reports/TaskReports";
import WorkloadReport from "./Reports/WorkloadReport";
import WorkloadDetails from "./Reports/WorkloadReport/components/workloadDetails";
import Configuration from "./Reports/components/Configuration";
import Timezone from "./Timezone";
import EditEmployee from "./staff/Employee/EditEmployee";
import ViewEmployee from "./staff/Employee/ViewEmployee";
import StaffPerformance from "./staff/Performance";
import ViewUserCalendar from "./Calendar/components/ViewUserCalendar";
import TaskDependancy from "./TaskDependancy";
import Translate from "../translation/utils/translateFunction";
import Forgotpassword from "./Authentication/components/ForgotPassword";
import SetNewPassword from "./Authentication/components/SetNewPassword";
import TaskDependancyMobile from "./Authentication/taskDependancyMobile";
import LeaveRequestList from "./Reports/AttendanceReport/LeaveRequestList";
import MyeventsList from "./MOM/MOMlistView/Myevents";
import SharedWithMeList from "./MOM/MOMlistView/SharedWithMe";
import MomDetailView from "./MOM/MOMdetailView";
import MomEdit from "./MOM/MOMEdit";
import SharedMOMUsers from "./MOM/SharedUsers";
import EmployeeRequestListing from "./ManageTask/EmployeeRequest";
import AddProject from "./Project/Add";
import ProjectReport from "./Reports/ProjectReport";
import CompanyReport from "./CustomReports/CompanyReport";

const RoutesPath = [
  {
    path: "/",
    name: "dashboard",
    exact: true,
    pageTitle: Translate("dashboard:dashboard"),
    component: Dashboard,
  },
  // {
  //   path: "/hr-users",
  //   name: "hr-users",
  //   exact: true,
  //   pageTitle: "Users",
  //   component: Users,
  // },
  // {
  //   path: "/hr-department",
  //   name: "department",
  //   exact: true,
  //   pageTitle: "Departments",
  //   component: Departments,
  // },
  // {
  //   path: "/hr-employee",
  //   name: "employee",
  //   exact: true,
  //   pageTitle: "Employee",
  //   component: Employee,
  // },
  // {
  //   path: "/hr-activities",
  //   name: "activities",
  //   exact: true,
  //   pageTitle: "Activities",
  //   component: Activities,
  // },
  // {
  //   path: "/hr-holidays",
  //   name: "holidays",
  //   exact: true,
  //   pageTitle: "Holidays",
  //   component: Holidays,
  // },
  // {
  //   path: "/hr-events",
  //   name: "events",
  //   exact: true,
  //   pageTitle: "Events",
  //   component: Events,
  // },
  // {
  //   path: "/hr-payroll",
  //   name: "payroll",
  //   exact: true,
  //   pageTitle: "Payroll",
  //   component: Payroll,
  // },
  // {
  //   path: "/hr-accounts",
  //   name: "accounts",
  //   exact: true,
  //   pageTitle: "Accounts",
  //   component: Accounts,
  // },
  // {
  //   path: "/hr-report",
  //   name: "report",
  //   exact: true,
  //   pageTitle: "Report",
  //   component: Report,
  // },
  // // add new routes here

  // //project

  // {
  //   path: "/project-dashboard",
  //   name: "projectDashboard",
  //   exact: true,
  //   pageTitle: "'Project Dashboard",
  //   component: ProjectDashboard,
  // },
  // {
  //   path: "/project-list",
  //   name: "project-list",
  //   exact: true,
  //   pageTitle: "Project",
  //   component: ProjectList,
  // },

  // {
  //   path: "/project-taskboard",
  //   name: "project-taskboard",
  //   exact: true,
  //   pageTitle: "Taskboard",
  //   component: Taskboard,
  // },

  // {
  //   path: "/project-ticket",
  //   name: "project-ticket",
  //   exact: true,
  //   pageTitle: "Ticket List",
  //   component: TicketList,
  // },

  // {
  //   path: "/project-ticket-details",
  //   name: "project-ticket-details",
  //   exact: true,
  //   pageTitle: "Ticket Details",
  //   component: TicketDetails,
  // },
  // {
  //   path: "/project-clients",
  //   name: "project-clients",
  //   exact: true,
  //   pageTitle: "Clients",
  //   component: Clients,
  // },

  // {
  //   path: "/project-todo",
  //   name: "project-todo",
  //   exact: true,
  //   pageTitle: "Todo List",
  //   component: TodoList,
  // },

  // //job portal

  // {
  //   path: "/jobportal-dashboard",
  //   name: "jobportalDashboard",
  //   exact: true,
  //   pageTitle: "Job Dashboard",
  //   component: JobPortalDashboard,
  // },
  // {
  //   path: "/jobportal-positions",
  //   name: "jobportalPositions",
  //   exact: true,
  //   pageTitle: "Job Positions",
  //   component: Positions,
  // },
  // {
  //   path: "/jobportal-applicants",
  //   name: "jobportalpplicants",
  //   exact: true,
  //   pageTitle: "Job Applicants",
  //   component: Applicants,
  // },
  // {
  //   path: "/jobportal-resumes",
  //   name: "jobportalResumes",
  //   exact: true,
  //   pageTitle: "Job Resumes",
  //   component: Resumes,
  // },
  // {
  //   path: "/jobportal-settings",
  //   name: "jobportalSettings",
  //   exact: true,
  //   pageTitle: "Job Settings",
  //   component: Settings,
  // },

  // {
  //   path: "/login",
  //   name: "login",
  //   exact: true,
  //   pageTitle: "Tables",
  //   component: Login,
  // },
  {
    path: "/signup",
    name: "signup",
    exact: true,
    pageTitle: "Tables",
    component: Signup,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    exact: true,
    pageTitle: "Tables",
    component: ForgotPassword,
  },
  {
    path: "/notfound",
    name: "notfound",
    exact: true,
    pageTitle: "Tables",
    component: NotFound,
  },
  {
    path: "/internalserver",
    name: "internalserver",
    exact: true,
    pageTitle: "Tables",
    component: InternalServer,
  },

  // {
  //   path: "/icons",
  //   name: "icons",
  //   exact: true,
  //   pageTitle: "Icons",
  //   component: Icons,
  // },
  // {
  //   path: "/icons-feather",
  //   name: "icons-feather",
  //   exact: true,
  //   pageTitle: "Icons",
  //   component: IconsFeather,
  // },
  // {
  //   path: "/icons-line",
  //   name: "icons-line",
  //   exact: true,
  //   pageTitle: "Icons",
  //   component: IconsLine,
  // },
  // {
  //   path: "/icons-flag",
  //   name: "icons-flag",
  //   exact: true,
  //   pageTitle: "Icons",
  //   component: IconsFlags,
  // },
  // {
  //   path: "/icons-payments",
  //   name: "icons-payments",
  //   exact: true,
  //   pageTitle: "Icons",
  //   component: IconsPayments,
  // },
  // {
  //   path: "/charts",
  //   name: "charts",
  //   exact: true,
  //   pageTitle: "Charts",
  //   component: Charts,
  // },
  // {
  //   path: "/charts-e",
  //   name: "charts-e",
  //   exact: true,
  //   pageTitle: "Charts",
  //   component: ChartsE,
  // },
  // {
  //   path: "/charts-c3",
  //   name: "charts-c3",
  //   exact: true,
  //   pageTitle: "Charts",
  //   component: ChartsC3,
  // },
  // {
  //   path: "/charts-knob",
  //   name: "charts-knob",
  //   exact: true,
  //   pageTitle: "Charts",
  //   component: ChartsKnob,
  // },
  // {
  //   path: "/charts-sparkline",
  //   name: "charts-sparkline",
  //   exact: true,
  //   pageTitle: "Charts",
  //   component: ChartsSparkline,
  // },

  // {
  //   path: "/forms",
  //   name: "forms",
  //   exact: true,
  //   pageTitle: "Forms Elements",
  //   component: Forms,
  // },
  // {
  //   path: "/form-advanced",
  //   name: "form-advanced",
  //   exact: true,
  //   pageTitle: "Forms Elements",
  //   component: FormAdvanced,
  // },
  // {
  //   path: "/form-validation",
  //   name: "form-validation",
  //   exact: true,
  //   pageTitle: "Forms Elements",
  //   component: FormValidation,
  // },
  // {
  //   path: "/form-wizard",
  //   name: "form-wizard",
  //   exact: true,
  //   pageTitle: "Forms Elements",
  //   component: FormWizard,
  // },
  // {
  //   path: "/form-summernote",
  //   name: "form-summernote",
  //   exact: true,
  //   pageTitle: "Forms Elements",
  //   component: FormSummernote,
  // },

  // {
  //   path: "/tables",
  //   name: "tables",
  //   exact: true,
  //   pageTitle: "Tables",
  //   component: Tables,
  // },
  // {
  //   path: "/tables-datatable",
  //   name: "tables-datatable",
  //   exact: true,
  //   pageTitle: "Tables",
  //   component: DataTables,
  // },
  // {
  //   path: "/tables-color",
  //   name: "tables-color",
  //   exact: true,
  //   pageTitle: "Tables",
  //   component: TablesColor,
  // },
  // {
  //   path: "/tables-basic",
  //   name: "tables-basic",
  //   exact: true,
  //   pageTitle: "Tables",
  //   component: TablesBasic,
  // },

  // {
  //   path: "/widgets",
  //   name: "widgets",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: Widgets,
  // },
  // {
  //   path: "/w-card",
  //   name: "w-card",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: WCard,
  // },
  // {
  //   path: "/w-statistics",
  //   name: "w-statistics",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: WStatistics,
  // },
  // {
  //   path: "/w-data",
  //   name: "w-data",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: WData,
  // },
  // {
  //   path: "/w-social",
  //   name: "w-social",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: WSocial,
  // },
  // {
  //   path: "/w-other",
  //   name: "w-other",
  //   exact: true,
  //   pageTitle: "Widgets",
  //   component: WOther,
  // },
  // {
  //   path: "/page-search",
  //   name: "page-search",
  //   exact: true,
  //   pageTitle: "Search",
  //   component: Search,
  // },
  // {
  //   path: "/app-calendar",
  //   name: "app-calendar",
  //   exact: true,
  //   pageTitle: "Calendar",
  //   component: AppCalendar,
  // },
  // {
  //   path: "/app-contact",
  //   name: "app-contact",
  //   exact: true,
  //   pageTitle: "Contact",
  //   component: AppContact,
  // },
  {
    path: "/message",
    name: "Message",
    exact: true,
    pageTitle: Translate("common:message"),
    component: MessageScreen,
  },
  {
    path: "/calendar",
    name: "Calendar",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: Calendar,
  },
  {
    path: "/calendar/view",
    name: "Calendar",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: ViewUserCalendar,
  },
  {
    path: "/calendar/createEvent",
    name: "Create Event",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: CreateEvent,
  },
  {
    path: "/calendar/viewEvent",
    name: "View Event",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: ViewEvent,
  },
  {
    path: "/calendar/editEvent",
    name: "Edit Event",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: EditEvent,
  },
  {
    path: "/calendar/reminder",
    name: "Reminder",
    exact: true,
    pageTitle: Translate("common:calendar"),
    component: SetReminder,
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    exact: true,
    pageTitle: "Forgot Password",
    component: Forgotpassword,
  },

  // {
  //   path: "/app-filemanager",
  //   name: "app-filemanager",
  //   exact: true,
  //   pageTitle: "File Manager",
  //   component: AppFilemanager,
  // },
  // {
  //   path: "/app-setting",
  //   name: "app-setting",
  //   exact: true,
  //   pageTitle: "App Setting",
  //   component: AppSetting,
  // },
  {
    path: "/profile",
    name: "profile",
    exact: true,
    pageTitle: Translate("common:account"),
    component: Profile,
  },
  {
    path: "/inbox/inboxList",
    name: "Inbox",
    exact: true,
    pageTitle: Translate("common:inbox"),
    component: InboxList,
  },
  {
    path: "/inbox/sentMail",
    name: "Sent mail",
    exact: true,
    pageTitle: Translate("common:inbox"),
    component: SentMailList,
  },
  {
    path: "/inbox/viewEmailDetails",
    name: "View email details",
    exact: true,
    pageTitle: Translate("common:inbox"),
    component: ViewEmailDetails,
  },

  // {
  //   path: "/maps",
  //   name: "maps",
  //   exact: true,
  //   pageTitle: "Vector Maps",
  //   component: Maps,
  // },
  // {
  //   path: "/gallery",
  //   name: "gallery",
  //   exact: true,
  //   pageTitle: "Image Gallery",
  //   component: Gallery,
  // },
  {
    path: "/getForms",
    name: "getForms",
    exact: true,
    pageTitle: "Get Forms",
    component: GetForms,
  },
  {
    path: "/contactRepository",
    name: "contactRepository",
    exact: true,
    pageTitle: "Contact Repository",
    component: ContactRepository,
  },
  {
    path: "/contactRepository/privateListing",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:privateTitle"),
    component: ContactRepoPrivateListing,
  },
  {
    path: "/contactRepository/sharedListing/roles",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:shareTitle"),
    component: PrivateContactsSelectedMember,
  },
  {
    path: "/contactRepository/sharedListing",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:shareTitle"),
    component: SharedList,
  },
  {
    path: "/contactRepository/sharedListing/viewBusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:businessCard"),
    component: ViewCardSharedList,
  },
  {
    path: "/contactRepository/viewSelectMemberBusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:businessCard"),
    component: ViewBusinessCardSelectMember,
  },
  {
    path: "/contactRepository/viewSelectShareMemberBusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:businessCard"),
    component: ViewBusinessCardSelectMember,
  },
  {
    path: "/contactRepository/publicListing/addNewContact",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:publicTitle"),
    component: AddNewContact,
  },
  {
    path: "/contactRepository/privateListing/addNewContact",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:privateTitle"),
    component: AddNewContact,
  },

  {
    path: "/contactRepository/privateList/editContact",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:privateTitle"),
    component: EditContact,
  },
  {
    path: "/contactRepository/publicList/editContact",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:publicTitle"),
    component: EditContact,
  },
  {
    path: "/contactRepository/publicListing",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:publicTitle"),
    component: PublicListContactRepo,
  },
  {
    path: "/contactRepository/publicListing/viewBusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:businessCard"),
    component: PublicBusinessCard,
  },
  {
    path: "/contactRepository/publicListing/accessLogs",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:publicTitle"),
    component: PublicListAccessLogs,
  },
  {
    path: "/contactRepository/publicListing/BusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:publicTitle"),
    component: BusinessCardList,
  },
  {
    path: "/contactRepository/privateListing/viewBusinessCard",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:businessCard"),
    component: ViewBusinessCard,
  },
  {
    path: "/contactRepository/privateListing/accessLogs",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("drawer:privateTitle"),
    component: AccessLogs,
  },
  {
    path: "/contactRepository/privateListing/selectMember",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:selectMemberHeading"),
    component: SelectMember,
  },
  {
    path: "/contactRepository/sharedListing/selectMember",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:selectMemberHeading"),
    component: SelectMember,
  },
  {
    path: "/contactRepository/privateContacts",
    name: "contactRepository",
    exact: true,
    pageTitle: Translate("contacts:privateContacts"),
    component: PrivateContactsSelectedMember,
  },
  {
    path: "/voiceNotes/myVoiceNotes",
    name: "voiceNotes",
    exact: true,
    pageTitle: Translate("drawer:myVoiceNotes"),

    component: VoiceNotesListing,
  },
  {
    path: "/voiceNotes/sharedWithMe",
    name: "voiceNotes",
    exact: true,
    pageTitle: Translate("drawer:sharedWithMeVoiceNotes"),

    component: SharedWithMe,
  },
  {
    path: "/setReminder",
    name: "setReminder",
    exact: true,
    pageTitle: Translate("VoiceNotes:reminder"),

    component: SetReminder,
  },
  {
    path: "/voiceNotes/myVoiceNotes/accessLogs",
    name: "voiceNotes/accessLogs",
    exact: true,
    pageTitle: Translate("drawer:myVoiceNotes"),
    component: AccessLogsVoiceNote,
  },
  {
    path: "/renewals",
    name: "renewals",
    exact: true,
    pageTitle: Translate("drawer:renewals"),
    component: Renewals,
  },
  {
    path: "/renewals/add",
    name: "addRenewals",
    exact: true,
    pageTitle: Translate("drawer:renewals"),
    component: AddRenewals,
  },
  {
    path: "/renewals/view",
    name: "viewRenewals",
    exact: true,
    pageTitle: Translate("drawer:renewals"),
    component: ViewRenewals,
  },
  {
    path: "/renewals/edit",
    name: "editRenewals",
    exact: true,
    pageTitle: Translate("drawer:renewals"),
    component: EditRenewals,
  },
  {
    path: "/reports/performance",
    name: "performanceReport",
    exact: true,
    pageTitle: Translate("drawer:performanceReport"),
    component: PerformanceReport,
  },
  {
    path: "/performanceDetails",
    name: "performanceDetails",
    exact: true,
    pageTitle: Translate("drawer:performanceReport"),
    component: PerformanceDetails,
  },
  {
    path: "/workloadDetails",
    name: "workloadDetails",
    exact: true,
    pageTitle: Translate("drawer:workloadReport"),
    component: WorkloadDetails,
  },
  {
    path: "/reports/task",
    name: "taskReport",
    exact: true,
    pageTitle: Translate("drawer:taskReport"),
    component: TaskReports,
  },
  {
    path: "/reports/staff",
    name: "staffReport",
    exact: true,
    pageTitle: Translate("drawer:staffReport"),
    component: StaffReports,
  },
  {
    path: "/reports/staff/view",
    name: "viewStaffReport",
    exact: true,
    pageTitle: Translate("drawer:staffReport"),
    component: ViewStaffReport,
  },
  {
    path: "/reports/workload",
    name: "workloadReport",
    exact: true,
    pageTitle: Translate("drawer:workloadReport"),
    component: WorkloadReport,
  },
  {
    path: "/reports/attendance",
    name: "attendanceReport",
    exact: true,
    pageTitle: Translate("drawer:attendanceReport"),
    component: AttendanceReport,
  },
  {
    path: "/reports/configure",
    name: "configure",
    exact: true,
    pageTitle: Translate("drawer:attendanceReport"),
    component: Configuration,
  },
  {
    path: "/reports/attendance/view",
    name: "attendanceReport detail",
    exact: true,
    pageTitle: Translate("drawer:attendanceReport"),
    component: AttendanceReportDeatil,
  },
  {
    path: "/reports/attendance/leaveRequest",
    name: "attendanceReport",
    exact: true,
    pageTitle: Translate("drawer:attendanceReport"),
    component: LeaveRequestList,
  },
  {
    path: "/organizationStructure/company",
    name: "companyStructure",
    exact: true,
    pageTitle: Translate("drawer:companyStructure"),
    component: CompanyStructure,
  },
  {
    path: "/organizationStructure/report",
    name: "reportingStructure",
    exact: true,
    pageTitle: Translate("drawer:reportingStructure"),
    component: ReportingStructure,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    exact: true,
    pageTitle: Translate("drawer:aboutUs"),
    component: AboutUs,
  },
  {
    path: "/privacy",
    name: "privacy",
    exact: true,
    pageTitle: Translate("drawer:privacy"),
    component: Privacy,
  },
  {
    path: "/termsConditions",
    name: "termsConditions",
    exact: true,
    pageTitle: Translate("drawer:termsConditions"),
    component: TermsCondition,
  },
  {
    path: "/managerListing",
    name: "managerListing",
    exact: true,
    pageTitle: Translate("drawer:manager"),
    component: ManagerListing,
  },
  {
    path: "/addManager",
    name: "addManager",
    exact: true,
    pageTitle: Translate("drawer:manager"),
    component: AddManager,
  },
  {
    path: "/ownerListing",
    name: "ownerListing",
    exact: true,
    pageTitle: Translate("drawer:owner"),
    component: OwnerListing,
  },
  {
    path: "/addOwner",
    name: "addOwner",
    exact: true,
    pageTitle: Translate("drawer:owner"),
    component: AddOwner,
  },
  {
    path: "/editOwner",
    name: "editOwner",
    exact: true,
    pageTitle: Translate("drawer:owner"),
    component: AddOwner,
  },
  {
    path: "/addCompany",
    name: "company",
    exact: true,
    pageTitle: Translate("drawer:company"),
    component: AddCompany,
  },
  {
    path: "/editOfficeLocation",
    name: "company",
    exact: true,
    pageTitle: Translate("addCompany:editCompany"),
    component: EditCompany,
  },
  {
    path: "/addTask",
    name: "addTask",
    exact: true,
    // pageTitle: "Task comments",
    pageTitle: Translate("manageTask:head"),
    component: AddTask,
  },
  {
    path: "/editTask",
    name: "editTask",
    exact: true,
    // pageTitle: "Task comments",
    pageTitle: Translate("manageTask:head"),
    component: AddTask,
  },
  // route for managetask
  {
    path: "/manageTask",
    name: "managetask",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: ManageTask,
  },
  {
    path: "/taskDetails",
    name: "taskDetails",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: AddCommentListing,
  },
  {
    path: "/task",
    name: "taskDetails",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: AddCommentListing,
  },
  {
    path: "/taskReminder",
    name: "taskReminder",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: SetReminder,
  },
  {
    path: "/pinnedListing",
    name: "PinnedListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: PinnedTaskListing,
  },
  {
    path: "/selfAssignedTask",
    name: "SelfAssignedTaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: SelfAssignedTaskListing,
  },
  {
    path: "/reportedByMeTask",
    name: "reportedByMeTask",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: ReportedByMeTaskListing,
  },
  {
    path: "/reAllocationRequestTask",
    name: "reAllocationRequestTask",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: ReAllocationRequestListing,
  },
  {
    path: "/pendingApprovalTask",
    name: "PendingApprovalTaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: PendingApprovalTaskListing,
  },
  {
    path: "/rejectedTaskListing",
    name: "RejectedTaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: RejectedTaskListing,
  },
  {
    path: "/assignedToMeTaskListing",
    name: "AssignedToMeTaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: AssignedToMeTaskListing,
  },
  {
    path: "/reportedByPA",
    name: "ReportedByPA",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: ReportedByPA,
  },
  {
    path: "/employeeRequestTask",
    name: "EmployeeRequest",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: EmployeeRequestListing,
  },
  {
    path: "/subtask/add",
    name: "addSubtask",
    exact: true,
    pageTitle: Translate("taskDetails:subtaskTitle"),
    component: AddSubTask,
  },
  {
    path: "/subtask/list",
    name: "subtaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: SubtaskListing,
  },
  {
    path: "/subtask/view",
    name: "viewSubtask",
    exact: true,
    pageTitle: Translate("taskDetails:subtaskHead"),
    component: ViewSubtask,
  },
  {
    path: "/subtask",
    name: "viewSubtask",
    exact: true,
    pageTitle: Translate("taskDetails:subtaskHead"),
    component: ViewSubtask,
  },
  {
    path: "/subtask/edit",
    name: "editSubtask",
    exact: true,
    pageTitle: Translate("taskDetails:subtaskTitle"),
    component: EditSubtask,
  },
  {
    path: "/generalManagerListing",
    name: "generalManagerListing",
    exact: true,
    pageTitle: Translate("drawer:generalManager"),
    component: GeneralManagerListing,
  },
  {
    path: "/addGeneralManager",
    name: "addGeneralManager",
    exact: true,
    pageTitle: Translate("drawer:generalManager"),
    component: AddGeneralManager,
  },
  {
    path: "/viewGeneralManager",
    name: "viewGeneralManager",
    exact: true,
    pageTitle: Translate("drawer:generalManager"),
    component: ViewGeneralManager,
  },
  {
    path: "/viewManager",
    name: "viewManager",
    exact: true,
    pageTitle: Translate("addManager:viewManagerHeading"),
    component: ViewManager,
  },
  {
    path: "/assignedByPATaskListing",
    name: "AssignedByPATaskListing",
    exact: true,
    pageTitle: Translate("manageTask:head"),
    component: AssignedByPATaskListing,
  },
  {
    path: "/relatedTaskListing",
    name: "RelatedTaskListing",
    exact: true,
    pageTitle: Translate("common:manageTask"),
    component: RelatedTaskListing,
  },
  {
    path: "/viewOfficeLocation",
    name: "viewOfficeLocation",
    exact: true,
    pageTitle: Translate("addCompany:viewOfficeHeader"),
    component: ViewCompany,
  },
  {
    path: "/viewOwner",
    name: "view owner",
    exact: true,
    pageTitle: Translate("drawer:owner"),
    component: ViewOwner,
  },
  // route for personal assistant
  {
    path: "/staff/pa",
    name: "personalAssistantListing",
    exact: true,
    pageTitle: Translate("drawer:personalAssistant"),
    component: PersonalAssistantListing,
  },
  {
    path: "/staff/pa/add",
    name: "addPersonalAssistant",
    exact: true,
    pageTitle: Translate("drawer:personalAssistant"),
    component: AddPersonalAssistant,
  },
  {
    path: "/staff/pa/edit",
    name: "editPersonalAssistant",
    exact: true,
    pageTitle: Translate("drawer:personalAssistant"),
    component: EditPersonalAssistant,
  },
  {
    path: "/staff/pa/view",
    name: "viewPersonalAssistant",
    exact: true,
    pageTitle: Translate("drawer:personalAssistant"),
    component: ViewPersonalAssistant,
  },
  // route for employee
  {
    path: "/employeeListing",
    name: "employeeListing",
    exact: true,
    pageTitle: Translate("drawer:employee"),
    component: EmployeeListing,
  },
  {
    path: "/addEmployee",
    name: "addEmployee",
    exact: true,
    pageTitle: Translate("drawer:employee"),
    component: AddEmployee,
  },
  {
    path: "/viewEmployee",
    name: "viewEmployee",
    exact: true,
    pageTitle: Translate("drawer:employee"),
    component: ViewEmployee,
  },
  {
    path: "/editEmployee",
    name: "editEmployee",
    exact: true,
    pageTitle: Translate("drawer:employee"),
    component: EditEmployee,
  },
  {
    path: "/staff/performance",
    name: "staffPerformance",
    exact: true,
    pageTitle: Translate("drawer:staffPerformance"),
    component: StaffPerformance,
  },
  // route for vendor
  {
    path: "/vendors",
    name: "vendorListing",
    exact: true,
    pageTitle: Translate("drawer:vendorsSuppliers"),
    component: VendorListing,
  },
  {
    path: "/vendors/add",
    name: "addVendor",
    exact: true,
    pageTitle: Translate("drawer:vendorsSuppliers"),
    component: AddVendor,
  },
  {
    path: "/vendors/view",
    name: "viewVendor",
    exact: true,
    pageTitle: Translate("drawer:vendorsSuppliers"),
    component: ViewVendor,
  },
  {
    path: "/vendors/edit",
    name: "editVendor",
    exact: true,
    pageTitle: Translate("drawer:vendorsSuppliers"),
    component: EditVendor,
  },
  // route for myDocuments
  {
    path: "/documentRepository/myDocuments",
    name: "myDocuments",
    exact: true,
    pageTitle: Translate("document:myDocumentsSectionHeading"),
    component: MyDocuments,
  },
  {
    path: "/documentRepository/myDocuments/add",
    name: "myDocuments/add",
    exact: true,
    pageTitle: Translate("document:myDocumentsSectionHeading"),
    component: AddMyDocument,
  },
  {
    path: "/documentRepository/myDocuments/edit",
    name: "myDocuments/edit",
    exact: true,
    pageTitle: Translate("document:myDocumentsSectionHeading"),
    component: EditMyDocument,
  },
  {
    path: "/documentRepository/myDocuments/view",
    name: "myDocuments/view",
    exact: true,
    pageTitle: Translate("document:myDocumentsSectionHeading"),
    component: ViewMyDocument,
  },
  {
    path: "/documentRepository/myDocuments/accessLogs",
    name: "myDocuments/accessLogs",
    exact: true,
    pageTitle: Translate("document:myDocumentsSectionHeading"),
    component: AccessLogsMyDocument,
  },
  // route for sharedDocuments
  {
    path: "/documentRepository/sharedWithMe",
    name: "sharedWithMe",
    exact: true,
    pageTitle: Translate("document:sharedWithMeSectionHeading"),
    component: SharedDocuments,
  },
  {
    path: "/documentRepository/sharedWithMe/view",
    name: "sharedWithMe/view",
    exact: true,
    pageTitle: Translate("document:sharedWithMeSectionHeading"),
    component: ViewSharedDocument,
  },
  {
    path: "/documentRepository/sharedWithMe/accessLogs",
    name: "sharedWithMe/accessLogs",
    exact: true,
    pageTitle: Translate("document:sharedWithMeSectionHeading"),
    component: AccessLogsSharedDocument,
  },
  {
    path: "/notifications",
    name: "notificationList",
    exact: true,
    pageTitle: Translate("notificationPage:notificationHeader"),
    component: NotificationListing,
  },
  {
    path: "/notifications/view",
    name: "viewNotification",
    exact: true,
    pageTitle: Translate("notificationPage:notificationHeader"),
    component: ViewNotification,
  },
  {
    path: "/activityLogs/owner",
    name: "ownerActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:owner"),
    component: OwnerActivityLogs,
  },
  {
    path: "/activityLogs/manager",
    name: "managerActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:manager"),
    component: ManagerActivityLogs,
  },
  {
    path: "/activityLogs/employee",
    name: "employeeActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:employee"),
    component: EmployeeActivityLogs,
  },
  {
    path: "/activityLogs/gm",
    name: "gmActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:generalManager"),
    component: GmActivityLogs,
  },
  {
    path: "/activityLogs/pa",
    name: "paActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:personalAssistant"),
    component: PaActivityLogs,
  },
  {
    path: "/activityLogs/vendor",
    name: "vendorActivitylogs",
    exact: true,
    pageTitle: Translate("drawer:vendor"),
    component: VendorActivityLogs,
  },

  {
    path: "/settings/changeTimezone",
    name: "changeTimezone",
    exact: true,
    pageTitle: Translate("drawer:changeTimeZone"),
    component: Timezone,
  },

  {
    path: "/settings/changePassword",
    name: "changePassword",
    exact: true,
    pageTitle: Translate("drawer:changePass"),
    component: ChangePassword,
  },
  {
    path: "/taskDetails/taskdependancy",
    name: "Task",
    exact: true,
    pageTitle: Translate("taskDetails:viewTaskDependencyLabel"),
    component: TaskDependancy,
  },
  {
    path: "/taskdependancy",
    name: "Task",
    exact: true,
    pageTitle: Translate("taskDetails:viewTaskDependencyLabel"),
    component: TaskDependancyMobile,
  },
  {
    path: "/calendar/addMom",
    name: "MOM",
    exact: true,
    pageTitle: Translate("common:addmom"),
    component: MomEdit,
  },
  {
    path: "/mom/myevent/editEvent",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:momMyEvent"),
    component: MomEdit,
  },
  {
    path: "/mom/myevent",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:momMyEvent"),
    component: MyeventsList,
  },
  {
    path: "/mom/sharedWithMe",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:sharedWithMeVoiceNotes"),
    component: SharedWithMeList,
  },
  {
    path: "/mom/myevent/view",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:momMyEvent"),
    component: MomDetailView,
  },
  {
    path: "/mom/sharedWithMe/view",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:sharedWithMeVoiceNotes"),
    component: MomDetailView,
  },
  {
    path: "/mom/myevent/sharedUsers",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:momMyEvent"),
    component: SharedMOMUsers,
  },
  {
    path: "/mom/sharedWithMe/sharedUsers",
    name: "MOM",
    exact: true,
    pageTitle: Translate("drawer:sharedWithMeVoiceNotes"),
    component: SharedMOMUsers,
  },
  {
    path: "/addProject",
    name: "Add project",
    exact: true,
    pageTitle: Translate("drawer:project"),
    component: AddProject,
  },
  {
    path: "/reports/project",
    name: "Project report",
    exact: true,
    pageTitle: Translate("drawer:projectReport"),
    component: ProjectReport,
  },
  {
    path: "/reports/custom/company",
    name: "Company report",
    exact: true,
    pageTitle: Translate("drawer:customReport"),
    component: CompanyReport,
  },
];

export default RoutesPath;
