export const userTypes = {
  Owner: "Owner",
  Manager: "Manager",
  Employee: "Employee",
  Vendor: "Vendor",
  GeneralManager: "GM",
  persoalAssistant: "PA",
  Company: "COMPANY",
  Task: "TASK",
  Project: "PROJECT",
};

export const sidebarType = {
  CHAT: "chat",
  CALENDAR: "calendar",
  EMAIL: "email",
};

export const moduleType = {
  FOOTER: "FOOTER",
};
