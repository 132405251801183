import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
  CustomProgressBarCellRender,
  CustomStatusCellRender,
} from "../../Components/utils";

export const getReportedByMeTaskColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
      flex: 0.17,
    },
    {
      field: "taskNumber",
      headerName: Translate("manageTask:taskIdTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.taskNumber,
    },
    // {
    //   field: "type",
    //   headerName: Translate("manageTask:taskTypeTitle"),
    //   flex: 0.35,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: CustomTaskTypeCellRender,
    // },
    {
      field: "title",
      headerName: Translate("manageTask:taskNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.title,
    },
    {
      field: "company.name",
      headerName: Translate("manageTask:companyNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params?.row?.company?.name,
    },
    {
      field: "assigneeName",
      headerName: Translate("manageTask:assigneeNameTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.assigneeName,
    },
    {
      field: "dueDate",
      headerName: Translate("manageTask:dueDateAndTimeTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomDueDateCellRender,
    },
    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "taskStatus",
      headerName: Translate("manageTask:statusTitle"),
      flex: 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomStatusCellRender,
    },
    {
      field: "taskProgress",
      headerName: Translate("manageTask:progressBar"),
      flex: 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomProgressBarCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};
