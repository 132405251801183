import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import moment from "moment-timezone";
import Translate from "../../../translation/utils/translateFunction";
import * as yup from "yup";
import { Regex } from "../ApiConstants";

export const convertToB64 = (acceptedFile) => {
  return new Promise((resolve) => {
    const file = acceptedFile;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

const units = ["B", "KB", "MB"];

export const toConvertKB = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const getSentenseCase = (str) => {
  if (!str) return "";

  const parsedString = str.replace(/[A-Z]/g, (char) => ` ${char}`).split(" ");
  const word1 = parsedString[1];
  const word2 = parsedString[2].replace(
    /[A-Z]/,
    (char) => `${char.toLowerCase()}`
  );
  return `${word1} ${word2}`;
};

export const getNameAndExtFromFile = (str) => {
  if (!str) return [];

  return str.split(/\.(?=[^\.]+$)/);
};

export const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export const getSentenseCaseforSingleWord = (str) => {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertToSentenceCase = (input) => {
  const words = input.split(" ");
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      // Capitalize the first letter of the first word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      // Convert the rest of the words to lowercase
      return word.toLowerCase();
    }
  });
  return formattedWords.join(" ");
};

export const convertCamelToTitleCase = (str) => {
  if (!str) return "";

  const res = str
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());

  return res;
};

export const validateDuplicateFiles = (file, files) => {
  const isDuplicate = files?.some(
    (item) => item?.name === file?.name && item?.size === file?.size
  );

  if (isDuplicate) {
    return { isDuplicate: true, fileName: file?.name };
  }

  return false;
};

export const isRtl = () => {
  const rtl = localStorage.getItem("isRtl");

  return rtl === "true";
};

export const getDayJs = (data) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.login ? dayjs(data).tz(userData?.login?.timezone) : dayjs();
};
export const getMoment = (data) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.login
    ? moment(data).tz(userData?.login?.timezone)
    : moment();
};

export const getFileExtFromUrlAndB64 = (str) => {
  if (!str) return "";

  // for b64
  if (str?.startsWith("data:")) {
    const matches = str?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

    if (matches && matches?.length === 3) {
      const contentType = matches[1];
      const ext = contentType.split("/")[1].split(";")[0];

      return ext;
    }
    // for url
  } else if (str?.startsWith("http://") || str.startsWith("https://")) {
    const urlParts = str?.split(".");
    const ext = urlParts[urlParts?.length - 1];

    return ext;
  }

  return "";
};
export const getAddressError = (field, setFieldError) => {
  switch (field) {
    case "workAddress.zipCode":
      setFieldError("workZipCode", Translate("addManager:zipcodeError_2"));
      return true;
    case "residenceAddress.zipCode":
      setFieldError("resZipCode", Translate("addManager:zipcodeError_2"));
      return true;
    case "residenceAddress.country":
      setFieldError("resCountry", Translate("addManager:countryError"));
      return true;
    case "residenceAddress.state":
      setFieldError("resState", Translate("addManager:stateError"));
      return true;
    case "workAddress.country":
      setFieldError("workCountry", Translate("addManager:countryError"));
      return true;
    case "workAddress.state":
      setFieldError("workState", Translate("addManager:stateError"));
      return true;
    case "officeAddress.country":
      setFieldError("country", Translate("addManager:countryError"));
      return true;
    case "officeAddress.state":
      setFieldError("state", Translate("addManager:stateError"));
      return true;
    case "officeAddress.zipcode":
      setFieldError("zipcode", Translate("addManager:zipcodeError_2"));
      return true;
    default:
      return false;
  }
};

export const validateMobileSchema = (fieldName, msg) => {
  return {
    [fieldName]: yup
      .string()
      .trim()
      .required(msg)
      .test("noEmptyVal", msg, (value) => {
        if (!value) return true;

        // Check for empty parts within a string containing a dash
        return !value.split("-").some((part) => part === "");
      }),
  };
};

export const validateUrl = (str = "") => {
  const urlPattern = Regex?.urlRegex;

  return urlPattern.test(str);
};

export const handleEventKeySubmit = (event, onSubmit) => {
  if (event?.key === "Enter" || event?.keyCode === 13) {
    const isRestrictedEvent =
      event?.target?.tagName === "LI" ||
      event?.target?.className === "search-box" ||
      event?.target?.className.includes("MuiSelect-select") ||
      (event?.target?.className.includes("MuiList-padding MuiMenu-list") &&
        event?.target?.id === ":r9:") ||
      event?.target?.id === "react-select-3-input" ||
      event?.target?.id === "mui-component-select-reminder" ||
      event?.target?.id === "react-select-5-input";

    event.preventDefault();

    if (!isRestrictedEvent) {
      onSubmit(event);
    }
  }
};

export const stopEventPropogation = (event) => event.stopPropagation();

export const checkModuleAccess = (data, moduleName) => {
  const keyName = moduleName.toLowerCase();

  const moduleAccess = data?.find((item) => {
    return Object.keys(item).some(
      (key) => key.toLowerCase() === keyName && item[key]?.isEnable
    );
  });

  if (!moduleAccess) return false;

  return true;
};

export const getDropdownOptions = (
  data,
  labelKey,
  valueKey,
  noKeys = false
) => {
  if (!data?.length) return [];

  const options = data.map((item) => ({
    label: noKeys ? item : item[labelKey],
    value: noKeys ? item : item[valueKey],
  }));
  return options;
};
export const getMenuAccessObject = (menuList, label) => {
  const getData = menuList?.find((item) => item[label] === label);
  return getData[label];
};
