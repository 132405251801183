import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetLeaveRequestListMutation,
  useProcessLeaveRequestMutation,
} from "../../../../request/Report";
import {
  StyledCard,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import ListView from "../../../ListView";
import { getLeaveRequestColumns } from "./components/column.data";
import { Grid, Stack } from "@mui/material";
import Translate from "../../../../translation/utils/translateFunction";
import { colors } from "../../../../common/Theme/Colors";
import { showToast } from "../../../../common/Utils/ToastMessage";
import AlertPermission from "../../../AlertPermission";
import { leaveApprovalType } from "./components/constants";

const LeaveRequestList = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState("");

  const [
    getLeaveRequestList,
    {
      data: leaveReqData,
      isSuccess: isLeaveReqSuccess,
      isLoading: isLeaveReqLoading,
      error: leaveReqError,
      isError: isLeaveReqError,
    },
  ] = useGetLeaveRequestListMutation();

  const [
    processLeaveRequest,
    {
      data: leaveReqApprovalData,
      isSuccess: isLeaveReqApprovalSuccess,
      isLoading: isLeaveReqApprovalLoading,
      error: leaveReqApprovalError,
      isError: isLeaveReqApprovalError,
    },
  ] = useProcessLeaveRequestMutation();

  useEffect(() => {
    setIsLoading(true);
    getLeaveRequestList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (isLeaveReqSuccess && leaveReqData?.data) {
      const { pageInfo, nodes } = leaveReqData?.data;
      setLeaveRequests(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (leaveReqError && isLeaveReqError) {
      showToast({
        type: "error",
        message: leaveReqError?.data,
      });
      setIsLoading(false);
    }
  }, [leaveReqData, isLeaveReqSuccess, leaveReqError, isLeaveReqError]);

  useEffect(() => {
    setSelectedLeaveId("");
    if (isLeaveReqApprovalSuccess && leaveReqApprovalData) {
      showToast({
        type: "success",
        message: leaveReqApprovalData?.message,
      });
      setCurrentPage(1);
      getLeaveRequestList(1);
    }

    if (leaveReqApprovalError && isLeaveReqApprovalError) {
      showToast({
        type: "error",
        message: leaveReqApprovalError?.data?.message,
      });
    }
  }, [
    leaveReqApprovalData,
    isLeaveReqApprovalSuccess,
    leaveReqApprovalError,
    isLeaveReqApprovalError,
  ]);

  const handleLeaveApproval = (leaveId, leaveType) => {
    setSelectedLeaveId(leaveId);
    if (leaveType === leaveApprovalType?.APPROVED) {
      processLeaveRequest({ requestId: leaveId, leaveStatus: leaveType });
      onCancelAlert();
    } else if (leaveType === leaveApprovalType?.DISAPPROVED) {
      setShowAlert(true);
    }
  };

  const onAcceptAlert = () => {
    processLeaveRequest({
      requestId: selectedLeaveId,
      leaveStatus: leaveApprovalType?.DISAPPROVED,
    });
    onCancelAlert();
  };

  const onCancelAlert = () => setShowAlert(false);

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    const { _id } = params?.row;

    return (
      <div className="flexCenter" style={{ gap: "20px" }}>
        <button
          className="btn taskAcceptBtn saveButton"
          onClick={() => handleLeaveApproval(_id, leaveApprovalType?.APPROVED)}
        >
          {Translate("taskDetails:approveButtonLabel")}
        </button>
        <button
          className="btn taskRejectBtn saveButton"
          onClick={() =>
            handleLeaveApproval(_id, leaveApprovalType?.DISAPPROVED)
          }
        >
          {Translate("taskDetails:disapproveButtonLabel")}
        </button>
      </div>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div className="card">
              <div className="card-header card-header-spacing formTitle">
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <i
                    className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                    onClick={() => navigate("/reports/attendance")}
                    title={Translate("common:backToolTip")}
                  />
                  <h5 className="page-title">
                    {Translate("reports:leaveRequest")}
                  </h5>
                </Stack>
              </div>
              <div className="card-body">
                <ListView
                  columns={getLeaveRequestColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={leaveRequests}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLeaveReqLoading}
                  showLoader={
                    isLoading || isLeaveReqLoading || isLeaveReqApprovalLoading
                  }
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <AlertPermission
        show={showAlert}
        subTitle={Translate("reports:leaveDisApproveAlert")}
        onOk={onAcceptAlert}
        onCanel={onCancelAlert}
        okTitle="Yes"
        cancelTitle="No"
      />
    </Grid>
  );
};

export default LeaveRequestList;
