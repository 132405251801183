import homePage from "./homePage";
import common from "./common";
import drawer from "./drawer";
import dashboard from "./Dashboard/dashboard";
import selectCompany from "./Auth/selectCompany";
import manageTask from "./ManageTask/manageTask";
import renewals from "./Renewals/renewals";
import vendors from "./vendors_suppliers/vendors";
import addPersonalAssistant from "./Home/addPersonalAssistant";
import VoiceNotes from "./VoiceNotes/VoiceNotes";
import contacts from "./ContactRepository/contacts";
import businessCard from "./ContactRepository/businessCard";
import performanceReport from "./PerformanceReport/performanceReport";
import workloadReport from "./WorkloadReport/workloadReport";
import document from "./DocumentRepository/document";
import assign from "./DocumentRepository/assign";
import addCompany from "./Home/addCompany";
import notificationPage from "./Notification/notificationPage";
import changePassword from "./Auth/changePassword";
import timeZone from "./Timezone/timeZone";
import addEmployee from "./Home/addEmployee";
import addOwner from "./Home/addOwner";
import addManager from "./Home/addManager";
import addTask from "./Home/addTask";
import taskDetails from "./ManageTask/taskDetails";
import login from "./Auth/login";
import activityLog from "./ActivityLog/activityLog";
import calendarPage from "./Calendar/calendarPage";
import createEvent from "./Calendar/createEvent";
import inboxPage from "./Inbox/inboxPage";
import accountPage from "./Account/accountPage";
import taskReport from "./TaskReport/taskReport";
import staffReport from "./StaffReport/staffReport";
import attendance from "./Account/attendance";
import reports from "./Reports/reports";
import filter from "./Filters/filter";
import messagePage from "./Messages/messagePage";
import group from "./Messages/group";
import viewContact from "./Messages/viewContact";
import forgotPassword from "./Auth/forgotPassword";
import momPage from "./Mom/momPage";
import project from "./Project";
import customReport from "./CustomReport";

export default {
  homePage,
  drawer,
  common,
  dashboard,
  selectCompany,
  manageTask,
  renewals,
  vendors,
  addPersonalAssistant,
  VoiceNotes,
  contacts,
  businessCard,
  performanceReport,
  workloadReport,
  document,
  assign,
  addCompany,
  notificationPage,
  changePassword,
  timeZone,
  addOwner,
  addManager,
  addEmployee,
  addTask,
  taskDetails,
  login,
  activityLog,
  calendarPage,
  createEvent,
  inboxPage,
  accountPage,
  taskReport,
  staffReport,
  attendance,
  reports,
  filter,
  messagePage,
  group,
  viewContact,
  forgotPassword,
  momPage,
  project,
  customReport
};
