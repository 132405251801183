import { Stack } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { colors } from "../../../../common/Theme/Colors";
import { userType as userTypes } from "../../../../common/Utils/ApiConstants";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useDeleteTaskMutation } from "../../../../request/Task";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import IconifyIcon from "../../../Icon";
import { teamStyle } from "../../../ManageTask/constants";
import ModalFormikForm from "../../../ModalForm/Formik";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import {
  getProgresbarColor,
  getStatusLabel,
} from "../../../Task/Components/utils";
import { styles } from "../../../TextField/TextField/index.Style";
import Loader from "../../../staff/Owner/LoadingComp";
import { taskApprovalEnums, taskStatusType } from "../../constants";
import { InitialValuesForRejected } from "./components/constants";
import { RejectedReasonSchema } from "./components/validators";
import TextWrapWithTooltip from "../../../TextWrapWithTooltip";

const TaskDetails = ({
  item,
  userDetail,
  taskApprovalRequest,
  showApproval,
  isSubtaskDetails,
  setSearchTextReport,
  assignTo,
  setAssignTo,
  options,
  onSaveChanges,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userData, isRtl } = useSelector((state) => state?.formanagement);
  const {
    taskId,
    taskNumber,
    formattedStartDate,
    formattedEndDate,
    startTime,
    dueTime,
    taskStatus,
    priority,
    isCritical = false,
    hasPinned = false,
    assignee,
    reassignTo,
    canReassigned,
    isDeletable,
  } = item;

  const taskStatusBtnColor = getProgresbarColor(taskStatus, true);

  const [showRejectReasonAlert, setShowRejectReasonAlert] = useState(false);
  const [rejectReasonAlertTitle, setRejectReasonAlertTitle] = useState("");
  const [show, setshow] = useState(false);
  const [alertTaskType, setAlertTaskType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertTitle, setShowAlertTitle] = useState("");
  const [alertAcceptLabel, setAlertAcceptLabel] = useState(
    Translate("common:ok")
  );

  const handleTaskByAlert = (taskType) => {
    switch (taskType) {
      case taskApprovalEnums?.DISAPPROVE_BY_REPORTER:
      case taskApprovalEnums?.DISAPPROVE_BY_MANAGER: {
        if (
          item?.isEmployeeSelfAssign &&
          item?.employeeSelfAssignCondition?.isEmployeeSelfAssignStartApprove
        ) {
          setShowAlertTitle(Translate("taskDetails:alertReopen"));
          setRejectReasonAlertTitle(Translate("taskDetails:reason"));
        } else {
          setShowAlertTitle(Translate("taskDetails:disapproveTaskAlert"));
          setRejectReasonAlertTitle(
            Translate("taskDetails:disapproveReasonAlert")
          );
        }

        break;
      }
      case taskApprovalEnums?.REJECT_BY_ASSIGNEE: {
        setShowAlertTitle(Translate("taskDetails:rejectTaskAlert"));
        setRejectReasonAlertTitle(Translate("taskDetails:rejectReasonAlert"));
        break;
      }
      case taskApprovalEnums?.REALLOCATION_REQUEST: {
        setAlertAcceptLabel(Translate("common:yes"));
        isSubtaskDetails
          ? setShowAlertTitle(Translate("taskDetails:alertReAllocationSubtask"))
          : setShowAlertTitle(Translate("taskDetails:alertReAllocationTask"));
        break;
      }
      case taskApprovalEnums?.DISAPPROVE_REALLOCATION_REQ: {
        setShowAlertTitle(Translate("taskDetails:alertReAllocationDisApprove"));
        setRejectReasonAlertTitle(
          Translate("taskDetails:alertReAllocationDisapproveReason")
        );
        break;
      }
      default:
        break;
    }

    setAlertTaskType(taskType);
    setShowAlert(true);
  };

  const onAcceptAlert = () => {
    if (
      [
        taskApprovalEnums?.DISAPPROVE_BY_REPORTER,
        taskApprovalEnums?.REJECT_BY_ASSIGNEE,
        taskApprovalEnums?.DISAPPROVE_REALLOCATION_REQ,
        taskApprovalEnums?.DISAPPROVE_BY_MANAGER,
      ].includes(alertTaskType)
    ) {
      setShowRejectReasonAlert(true);
    } else if (alertTaskType === taskApprovalEnums?.REALLOCATION_REQUEST) {
      taskApprovalRequest(alertTaskType, item?.taskId);
    }

    setShowAlert(false);
  };

  const onCancelAlert = () => {
    setShowAlert(false);
    setAlertTaskType("");
  };

  const onRejectReasonAlert = (value) => {
    setShowRejectReasonAlert(false);
    taskApprovalRequest(alertTaskType, value?.rejectedReason);
  };

  const onCancelRejectReasonAlert = () => setShowRejectReasonAlert(false);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {Translate("common:noDataFoundMessage")}
        </span>
      </components.NoOptionsMessage>
    );
  };

  const [
    deleteTaskApi,
    {
      data: deleteTaskData,
      isSuccess: successfullyDeletedTask,
      isLoading: isTaskDeleteLoading,
    },
  ] = useDeleteTaskMutation();

  useEffect(() => {
    if (successfullyDeletedTask && deleteTaskData) {
      showToast({ message: deleteTaskData.message, type: "success" });
      navigate("/manageTask", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTaskData]);

  const deleteTaskFunction = () => {
    const requestBody = {
      taskId: item?.taskId,
    };

    deleteTaskApi(requestBody);

    setshow(false);
  };
  const onCancel = () => setshow(false);

  const deleteTask = (value) => {
    if (value) {
      setshow(true);
    }
  };

  const showReAllocateBtn = () => {
    if (
      ((taskStatus === taskStatusType?.ASSIGNED &&
        userData?._id === item?.assignTo?._id) ||
        (taskStatus === taskStatusType?.ASSIGNED &&
          userData?._id === assignee?._id) ||
        (taskStatus === taskStatusType?.REASSIGNED &&
          userData?._id === reassignTo?._id)) &&
      !item?.selfAssignedReallocateButtonDisable &&
      !item?.isReallocationDisapproved &&
      userData?.role.type.toUpperCase() !== userTypes.Vendor.toUpperCase()
    ) {
      return true;
    }

    return false;
  };

  const handleReAllocationReq = (isReAllocate) => {
    if (isReAllocate) {
      return handleTaskByAlert(taskApprovalEnums?.REALLOCATION_REQUEST);
    }

    if (isSubtaskDetails) {
      showToast({
        message: Translate("taskDetails:reAllocationReqSubtaskError"),
        type: "error",
      });
    } else {
      showToast({
        message: Translate("taskDetails:reAllocationReqTaskError"),
        type: "error",
      });
    }
  };

  const handleEditTask = (isReAllocate) => {
    let reqBody = {
      isReAllocate,
      isRejected: taskStatus === taskStatusType?.REJECTED,
    };

    if (isSubtaskDetails) {
      reqBody = {
        isEdit: true,
        data: item,
        parentState: state,
        ...reqBody,
      };
    } else {
      reqBody = {
        edit: true,
        taskDetail: item,
        parentState: state,
        ...reqBody,
      };
    }

    return navigate(isSubtaskDetails ? "/subtask/edit" : "/editTask", {
      state: reqBody,
    });
  };

  const getActionBtnCount = () => {
    let actionBtnCount = 0;

    if (isDeletable) actionBtnCount++;
    if (item?.createGroupAccess) actionBtnCount++;
    if (item?.isEditable) actionBtnCount++;
    if (taskStatus !== taskStatusType?.COMPLETED) actionBtnCount++;

    return actionBtnCount;
  };

  const TopActionButtons = ({ isMenu }) => {
    return (
      <div className="taskActionButtons">
        {isDeletable && (
          <i
            className={`${"fa fa-trash"} cursor-pointer`}
            style={{ color: colors?.primary_010, height: "20px" }}
            onClick={() => deleteTask(true)}
            data-toggle="tooltip"
            title={Translate("common:deleteToolTip")}></i>
        )}

        {item?.createGroupAccess && (
          <span
            data-toggle="tooltip"
            style={{ position: "relative", bottom: "5px" }}
            title={Translate("taskDetails:createGroup")}>
            <IconifyIcon
              icon="ic:outline-group-add"
              style={{
                fontSize: isMenu ? "17px" : "20px",
                color: colors?.primary_010,
              }}
              className="ml-3 cursor-pointer"
              onClick={() =>
                navigate("/message", {
                  state: {
                    data: {
                      createGroupFromTask: true,
                      taskId: item?._id,
                      groupUsers: item?.groupUsers,
                    },
                  },
                })
              }
            />
          </span>
        )}

        {item?.isEditable && (
          <i
            className={`ml-3 ${"fa fa-pencil-square-o"} cursor-pointer`}
            style={{ color: colors?.primary_010, marginTop: "0.8px" }}
            onClick={() => handleEditTask(false)}
            data-toggle="tooltip"
            title={Translate("common:editToolTip")}></i>
        )}

        {taskStatus !== taskStatusType?.COMPLETED && (
          <i
            className="ml-3 fa fa-bell-o cursor-pointer reminBellIcon"
            style={{ color: colors?.primary_010 }}
            onClick={() =>
              navigate("/taskReminder", {
                state: {
                  taskDetail: item,
                  parentState: state,
                  isSubtask: isSubtaskDetails,
                },
              })
            }
            data-toggle="tooltip"
            title={Translate("taskDetails:reminderToolTip")}></i>
        )}
      </div>
    );
  };

  const ReAllocateBtn = () => {
    return (
      <button
        className="btn taskRejectBtn btnNoWrap saveButton"
        onClick={() => handleReAllocationReq(item?.canReallocate)}>
        {Translate("taskDetails:reAllocateButtonLabel")}
      </button>
    );
  };

  const AcceptRejectBtnByAssignee = () => {
    return (
      <>
        <button
          className="btn taskAcceptBtn saveButton"
          onClick={() =>
            taskApprovalRequest(taskApprovalEnums?.ACCEPT_BY_ASSIGNEE)
          }>
          {Translate("taskDetails:acceptButtonLabel")}
        </button>
        <button
          className="btn taskRejectBtn saveButton"
          onClick={() =>
            handleTaskByAlert(taskApprovalEnums?.REJECT_BY_ASSIGNEE)
          }>
          {Translate("taskDetails:rejectButtonLabel")}
        </button>
      </>
    );
  };

  return (
    <div key={taskId}>
      <div className="card">
        <div>
          <div className="taskHeader">
            <div className="taskInfoBox">
              <TextWrapWithTooltip
                textVariant="span"
                customClassName="taskProjectTitle TaskInfoTitle"
                label={`#${taskNumber}: ${
                  item?.projectTitle ? "Project" : "Independent task"
                }`}
                customStyle={isRtl && { direction: "ltr" }}
              />
              {isCritical && (
                <span>
                  <i
                    className={`ml-2 mb-2 ${"fa fa-flag-o"} iconFlagColorStyle`}
                  />
                </span>
              )}
              {hasPinned && (
                <span
                  className={`iconPinColorStyle fa fa-thumb-tack mx-2 mb-1 ml-2`}
                />
              )}
            </div>

            <div className="card-options">
              {getActionBtnCount() > 2 ? (
                <div className="taskActionMenu">
                  <a
                    href="/#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className="fa fa-ellipsis-v manageIconColor" />
                  </a>
                  <div className="dropdown-menu dropdownAlignment">
                    <div className="taskActionBtns">
                      <TopActionButtons isMenu />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="taskActions">
                  <TopActionButtons />
                </div>
              )}
            </div>
          </div>
          {item?.linkedWithChat && (
            <div
              className="cursor-pointer linkWithTask"
              onClick={() =>
                navigate("/message", {
                  state: { data: { ...item, _id: item?.chatId } },
                })
              }>
              <i
                className="fa fa-link font-18 "
                title={Translate("taskDetails:linkedwithChat")}></i>
              <h3 className="TaskInfoTitle pl-2">
                {Translate("taskDetails:linkedwithChat")}
              </h3>
            </div>
          )}
          {item?.linkedWithGroup && (
            <div
              className="cursor-pointer linkWithTask"
              onClick={() =>
                navigate("/message", {
                  state: {
                    data: {
                      ...item,
                      _id: item?.groupId[0],
                      groupId: item?.groupId[0],
                    },
                  },
                })
              }>
              <i
                className="fa fa-link font-18 "
                title={Translate("taskDetails:linkedWithGroup")}></i>
              <h3 className="TaskInfoTitle pl-2">
                {Translate("taskDetails:linkedWithGroup")}
              </h3>
            </div>
          )}
          {!!item?.emailId?.length && (
            <div
              className="cursor-pointer linkWithTask"
              onClick={() =>
                navigate("/inbox/viewEmailDetails", { state: item.emailId[0] })
              }>
              <i
                className="fa fa-link font-18"
                title={Translate("taskDetails:linkedwithEmail")}></i>
              <h3 className="TaskInfoTitle pl-2">
                {Translate("taskDetails:linkedwithEmail")}
              </h3>
            </div>
          )}

          <div className="card_spacing">
            <div className="mb-2">
              <div className="col-7 py-1">
                <span className="taskHeading">
                  {Translate("taskDetails:startDateLabel")}
                </span>
              </div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-calendar"}`}
                    style={{ display: "inline" }}></i>
                  {formattedStartDate}
                </div>
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-clock-o"}`}
                    style={{ display: "inline" }}></i>
                  {startTime}
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="col-7 py-1">
                <span className="taskHeading">
                  {Translate("taskDetails:dueDateLabel")}
                </span>
              </div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-calendar"}`}
                    style={{ display: "inline" }}></i>
                  {formattedEndDate}
                </div>
                <div className="taskCardContentStyling">
                  <i
                    className={`icon-color ${
                      isRtl ? "ml-2" : "mr-2"
                    } ${"fa fa-clock-o"}`}
                    style={{ display: "inline" }}></i>
                  {dueTime}
                </div>
              </div>
            </div>

            <div className="TaskStyling">
              <div
                style={{
                  borderColor: taskStatusBtnColor,
                  color: taskStatusBtnColor,
                }}
                className="taskDetailsStatus saveButton">
                {getStatusLabel(taskStatus)}
              </div>
              <div style={styles.taskDateStyle}>
                <span className={priority.toLowerCase()}>{priority}</span>
              </div>
            </div>
            <div className="float-right py-3">
              <ul className="list-unstyled" style={teamStyle}>
                {userDetail?.map((item, idx) => {
                  const userTitle =
                    idx === 0
                      ? Translate("taskDetails:reporter")
                      : idx === 1
                      ? Translate("taskDetails:assignee")
                      : Translate("taskDetails:reAssignee");
                  return (
                    item?.name && (
                      <li
                        key={idx}
                        data-toggle="tooltip"
                        title={`${userTitle}: ${item?.name}`}>
                        <Persona
                          name={item?.name}
                          image={
                            item?.profileUrl?.includes(VALIDATION_BUCKETURL)
                              ? item?.profileUrl
                              : ""
                          }
                          size={28}
                        />
                      </li>
                    )
                  );
                })}
                {/* <li className="ml-2">
                  <span>4+</span>
                </li> */}
              </ul>
            </div>

            {showApproval && (
              <>
                {item?.canReallocateApprove && (
                  <div className="mb-2">
                    <div className="col-7 py-3 taskApprovalButton">
                      <button
                        className="btn taskAcceptBtn saveButton"
                        onClick={() => handleEditTask(true)}>
                        {Translate("taskDetails:approveButtonLabel")}
                      </button>
                      <button
                        className="btn taskRejectBtn saveButton btnNoWrap"
                        onClick={() =>
                          handleTaskByAlert(
                            taskApprovalEnums?.DISAPPROVE_REALLOCATION_REQ
                          )
                        }>
                        {Translate("taskDetails:disapproveButtonLabel")}
                      </button>
                    </div>
                  </div>
                )}
                {(item?.canAcceptReject || showReAllocateBtn()) && (
                  <div className="mb-2 showReAllocateAcceptBtn">
                    <div className="col-7 py-3 taskApprovalButton">
                      <>
                        {showReAllocateBtn() && <ReAllocateBtn />}
                        {item?.canAcceptReject && <AcceptRejectBtnByAssignee />}
                      </>
                    </div>
                  </div>
                )}

                {showReAllocateBtn() && (
                  <div className="mb-2 showReAllocateBtn">
                    <div className="col-7 py-3 taskApprovalButton">
                      <ReAllocateBtn />
                    </div>
                  </div>
                )}

                {item?.canAcceptReject && (
                  <div className="mb-2 showAcceptRejectBtn">
                    <div className="col-7 taskApprovalButton">
                      <AcceptRejectBtnByAssignee />
                    </div>
                  </div>
                )}

                {item?.canStart && (
                  <div className="mb-2">
                    <div className="col-7 py-3 taskApprovalButton">
                      <button
                        className="btn taskAcceptBtn saveButton"
                        onClick={() =>
                          item?.addedBy === item?.assignee?._id
                            ? taskApprovalRequest(
                                taskApprovalEnums?.START_SELF_ASSIGNED
                              )
                            : taskApprovalRequest(taskApprovalEnums?.START)
                        }>
                        {Translate("taskDetails:startButtonLabel")}
                      </button>
                    </div>
                  </div>
                )}

                {item?.canMarkAsComplete && (
                  <div className="mb-2">
                    <div className="col-7 py-3 taskApprovalButton">
                      <button
                        className="btn taskAcceptBtn saveButton"
                        onClick={() =>
                          taskApprovalRequest(
                            taskApprovalEnums?.MARK_AS_COMPLETED
                          )
                        }>
                        {Translate("taskDetails:markAsCompletedButtonLabel")}
                      </button>
                    </div>
                  </div>
                )}

                {item?.canResolved && (
                  <div className="mb-2">
                    <div className="col-7 py-3 taskApprovalButton">
                      <button
                        className="btn taskAcceptBtn saveButton"
                        onClick={() =>
                          taskApprovalRequest(taskApprovalEnums?.RESOLVE)
                        }>
                        {Translate("taskDetails:resolveButtonLabel")}
                      </button>
                    </div>
                  </div>
                )}

                {(item?.canApproveDiapprove ||
                  item?.canPartialApproveDiapprove) && (
                  <div className="mb-2">
                    <div className="col-7 py-3 taskApprovalButton">
                      <button
                        className="btn taskAcceptBtn saveButton"
                        onClick={() =>
                          taskApprovalRequest(
                            item?.isEmployeeSelfAssign
                              ? taskApprovalEnums.APPROVE_BY_MANAGER
                              : taskApprovalEnums?.APPROVE_BY_REPORTER
                          )
                        }>
                        {Translate("taskDetails:approveButtonLabel")}
                      </button>
                      <button
                        className="btn taskRejectBtn saveButton btnNoWrap"
                        onClick={() =>
                          handleTaskByAlert(
                            item?.isEmployeeSelfAssign
                              ? taskApprovalEnums.DISAPPROVE_BY_MANAGER
                              : taskApprovalEnums?.DISAPPROVE_BY_REPORTER
                          )
                        }>
                        {Translate("taskDetails:disapproveButtonLabel")}
                      </button>
                    </div>
                  </div>
                )}
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  {canReassigned && options?.length > 0 && (
                    <div className="mb-2" style={{ width: "60%" }}>
                      <div className="form-group taskApprovalButton">
                        <Select
                          options={options}
                          value={assignTo}
                          onChange={setAssignTo}
                          onInputChange={setSearchTextReport}
                          className="form-control paddingStyle"
                          placeholder={Translate("taskDetails:reassign")}
                          classNamePrefix={"css-1s2u09g-control"}
                          components={{ NoOptionsMessage }}
                        />
                      </div>
                    </div>
                  )}
                  {canReassigned && assignTo && (
                    <div className="mb-2">
                      <div className="taskApprovalButton">
                        <button
                          className="btn taskAcceptBtn saveButton"
                          onClick={onSaveChanges}>
                          {Translate("taskDetails:saveChange")}
                        </button>
                      </div>
                    </div>
                  )}
                </Stack>
              </>
            )}
          </div>
        </div>
      </div>
      <AlertPermission
        show={showAlert}
        subTitle={showAlertTitle}
        okTitle={alertAcceptLabel}
        onOk={onAcceptAlert}
        onCanel={onCancelAlert}
      />
      <AlertPermission
        show={show}
        subTitle={Translate(
          `taskDetails:${isSubtaskDetails ? "deleteSubtask" : "deleteAlertMsg"}`
        )}
        okTitle={Translate("common:delete")}
        onOk={deleteTaskFunction}
        onCanel={onCancel}
      />
      <Loader loading={isTaskDeleteLoading}></Loader>
      <ModalFormikForm
        show={showRejectReasonAlert}
        title={rejectReasonAlertTitle}
        onOk={onRejectReasonAlert}
        onCancel={onCancelRejectReasonAlert}
        btnLabel={Translate("taskDetails:saveButtonLabel")}
        placeholder={Translate("taskDetails:reasonInputPlaceholder")}
        schema={RejectedReasonSchema}
        InitialValues={InitialValuesForRejected}
      />
    </div>
  );
};

export default memo(TaskDetails);
