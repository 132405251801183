import { Stack } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../common/Theme/Colors";
import { Regex } from "../../../common/Utils/ApiConstants";
import {
  daybeforeToday,
  monthDayYear,
} from "../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { getNameAndExtFromFile } from "../../../common/Utils/commonFunction";
import {
  useChatDetailsMutation,
  useGetGroupMemberListMutation,
  useSendMessageMutation,
} from "../../../request/Message";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import IconifyIcon from "../../Icon";
import CommonImage from "../../ImageGallery/CommonImage";
import { Persona } from "../../Persona";
import Loader from "../../staff/Owner/LoadingComp";
import ChatInfo from "../ChatInfo";
import GroupNameEdit from "../Components/EditGroupNameModal";
import MessageDetailItem from "../Components/MessageDetailitem";
import MessageListLoader from "../Components/MessageLoader/MessageListLoader";
import { tabType } from "../Components/constants";
import AddGroupMember from "../Groups/AddGroupMember";
import GroupInfo from "../Groups/GroupInfo";
import mentionInputstyle from "./styles";
import { setMessageType } from "./utils";
import TextWrapWithTooltip from "../../TextWrapWithTooltip";
import { userTypeOptions } from "../../../common/Utils/ApiConstants";
import {
  groupFilterOptions,
  chatFilterOptions,
  chatOptionType,
} from "./constants";

const { CHAT_LIST, GROUP_LIST } = tabType;

const MessageDetail = ({
  selectedChat,
  messageDetail,
  type,
  hasNextPage,
  isLoading,
  onClickLoadMore,
  isCreateScreen,
  onDeleteMessage,
  addToChatData,
  groupDetails,
  onClickForAddMember,
  onClickForSelectGroup,
  isNotification = false,
  onGroupIconUpdate,
  onSuccessFullEdit,
  onGroupIconDelete,
  onMessageFromGroup,
  isMsgAdded,
  handleMsgAdded,
}) => {
  const { userData, userType } = useSelector((state) => state.formanagement);
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [userInfo, setUserInfo] = useState();
  const [commonGroups, setCommonGroups] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [showAddMember, setShowAddMember] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [currentChat, setCurrentChat] = useState(selectedChat);
  const [chatFilters, setChatFilters] = useState([]);
  const [groupFilters, setGroupFilters] = useState([]);

  const hideInfoModal = () => setShowInfoModal(false);
  const visibleInfoModal = () => setShowInfoModal(true);
  const hideAddMemberModal = () => setShowAddMember(false);
  const visibleAddMemberModal = () => setShowAddMember(true);

  const removeData = () => {
    setFilesAdded([]);
    setImages([]);
    setValue("");
  };
  const [sentMessage, { data: messageData, isSuccess: isMsgSentSuccess }] =
    useSendMessageMutation();
  const [getChatInfo, { data: chatData, isSuccess }] = useChatDetailsMutation();

  const [
    getGroupMembersApi,
    {
      data: groupData,
      isSuccess: isGroupDetailSuccess,
      isError: isGroupDetailError,
      error: groupDetailError,
    },
  ] = useGetGroupMemberListMutation();

  const viewContactRequest = useMemo(() => {
    return {
      pageNo: pageNo,
      userId:
        type === GROUP_LIST ||
        isCreateScreen ||
        selectedChat?.isNewChatFromGroup
          ? selectedChat._id
          : userData?._id === selectedChat?.chatWith
          ? selectedChat?.chatOwner
          : selectedChat?.chatWith,
      chatOwner: selectedChat?.chatOwner,
      chatWith: selectedChat?.chatWith,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, selectedChat]);

  useEffect(() => {
    // update chat and group filter options
    if (userType) {
      if (userType === userTypeOptions?.VENDOR) {
        const chatFilterAcces = chatFilterOptions.filter(
          (item) => item !== chatOptionType?.RELATED_TASK
        );
        const groupFilterAcces = groupFilterOptions.filter(
          (item) => item !== chatOptionType?.RELATED_TASK
        );

        setChatFilters(chatFilterAcces);
        setGroupFilters(groupFilterAcces);
      } else {
        setChatFilters(chatFilterOptions);
        setGroupFilters(groupFilterOptions);
      }
    }
  }, [userType, selectedChat]);

  useEffect(() => {
    if (messageData && isMsgSentSuccess) {
      if (type === GROUP_LIST) {
        if (!currentChat.hasOwnProperty("groupId")) {
          setCurrentChat(messageData?.data);
        }
      }

      if (type === CHAT_LIST) {
        if (!currentChat.hasOwnProperty("chatId")) {
          setCurrentChat(messageData?.data);
        }
      }
    }
  }, [messageData, isMsgSentSuccess]);

  useEffect(() => {
    if (selectedChat?._id && type === GROUP_LIST) {
      getGroupMembersApi({ groupId: selectedChat?._id });
    }
  }, [selectedChat]);

  useEffect(() => {
    if ((messageDetail?.length && messageDetail?.length <= 15) || isMsgAdded) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
      handleMsgAdded(false);
    }
  }, [messageDetail, isMsgAdded]);

  useEffect(() => {
    type === CHAT_LIST && getChatInfo(viewContactRequest);
  }, [viewContactRequest]);

  useEffect(() => {
    if (chatData && isSuccess) {
      setUserInfo(chatData.data.userDetails);
      if (type !== GROUP_LIST) {
        setCommonGroups(chatData.data.record);
      }

      selectedChat?.isViewInfoFromGroup && visibleInfoModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    removeData();
  }, [selectedChat]);

  useEffect(() => {
    setGroupMembers([]);
    if (groupData && isGroupDetailSuccess) {
      const members = groupData?.data
        .map((member) =>
          member?.id !== userData?._id
            ? {
                ...member,
                display: member?.name,
              }
            : null
        )
        .filter((item) => item !== null); // skip the current user

      setGroupMembers([{ id: "1", display: "all" }, ...members]);
    }

    if (isGroupDetailError && groupDetailError) {
      showToast({
        message:
          groupDetailError?.data.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [groupData, isGroupDetailSuccess, isGroupDetailError, groupDetailError]);

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement?.type !== "application/pdf" && imageElement.preview
          ? imageElement.preview
          : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = () => {
    setFilesAdded([]);
  };

  const sendMessage = () => {
    if (value?.trim() || filesAdded?.length) {
      const receiverCheck =
        isCreateScreen || selectedChat?.isNewChatFromGroup
          ? selectedChat._id
          : userData?._id === selectedChat?.chatWith
          ? selectedChat?.chatOwner
          : selectedChat?.chatWith;
      let fileObj;

      if (filesAdded?.length) {
        fileObj = {
          url: filesAdded[0].b64,
          type: filesAdded[0].type,
          messageFileName: filesAdded[0].name,
          messageFileExt:
            getNameAndExtFromFile(filesAdded[0]?.name)?.length &&
            getNameAndExtFromFile(filesAdded[0]?.name)[1],
        };
      }

      let obj = {
        message: value?.trim(),
        isAttachment: !!filesAdded?.length,
        messageType: setMessageType(filesAdded[0]?.path),
        recieverId: receiverCheck,
        webAttachment: fileObj,
        senderProfileUrl: userData?.profileUrl,
        size: "",
      };

      if (
        [CHAT_LIST, GROUP_LIST].includes(type) ||
        isCreateScreen ||
        selectedChat?.isNewChatFromGroup
      ) {
        const tempId =
          isCreateScreen || selectedChat?.isNewChatFromGroup
            ? selectedChat?.chatId || addToChatData?._id
            : selectedChat?.chatId || selectedChat?._id;

        if (type === CHAT_LIST) obj.chatId = tempId;

        if (type === GROUP_LIST) {
          obj.groupId = tempId;

          if (value) {
            const { mentionWithDelimiter, mentionNoDelimiter } = Regex;
            const pattern = mentionWithDelimiter;
            const resultArray = value?.trim()?.split(pattern);
            let ids = [];
            let isAllUsers = false;

            resultArray.map((part) => {
              const match = mentionNoDelimiter.exec(part);
              if (match) {
                const id = match[2];

                // for all users
                if (id === "1") {
                  isAllUsers = true;

                  ids = groupMembers
                    ?.map((member) => (member?.id !== "1" ? member?.id : null))
                    .filter((item) => item !== null);
                }

                if (!isAllUsers) {
                  // store unique ids
                  if (!ids.includes(id)) {
                    ids.push(id);
                  }
                }
              }
            });

            obj.mentionedMember = ids;
          }
        }
      }

      if (!filesAdded.length) {
        delete obj.attachmentUrl;
      }

      bottomRef.current.scrollIntoView({ behavior: "smooth" });
      sentMessage(obj);
      removeData();
    }
  };

  const getTime = (currentMessage) => {
    const { formattedDate } = currentMessage;
    const time =
      monthDayYear(formattedDate) === monthDayYear()
        ? Translate("common:today")
        : monthDayYear(formattedDate) === monthDayYear(daybeforeToday())
        ? Translate("common:yesterday")
        : monthDayYear(formattedDate);
    return time;
  };

  const getFilterNavigate = (selectedFilter) => {
    if (selectedFilter === "relatedTask") {
      navigate("/relatedTaskListing", {
        state: {
          ...selectedChat,
          id: selectedChat?._id,
          type: type,
          moduleType: "Chat",
        },
      });
    } else {
      setShowEditModal(true);
    }
  };

  const handleAddAndRemoveMember = () => {
    onClickForAddMember();
    getGroupMembersApi({ groupId: selectedChat?._id });
  };

  return (
    <>
      <div className="container-fluid" style={{ width: "75%" }}>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card bg-none b-none">
              {selectedChat && (
                <div
                  className="card-header bline pt-1 msgHeader "
                  style={{
                    background: colors.grey_016,
                  }}>
                  <div className="msgHeaderSub">
                    <Stack
                      onClick={visibleInfoModal}
                      className="cursor-pointer">
                      <Persona
                        name={
                          selectedChat?.username ||
                          selectedChat?.userName ||
                          selectedChat?.name ||
                          groupDetails?.groupInfo?.groupName
                        }
                        image={
                          selectedChat?.userImage ||
                          selectedChat?.profileUrl ||
                          groupDetails?.groupInfo?.groupImage
                        }
                        size={40}
                      />
                    </Stack>
                    <Stack className="ml-2">
                      <TextWrapWithTooltip
                        textVariant="span"
                        customClassName="chatUserTitle"
                        label={
                          selectedChat?.username ||
                          selectedChat?.userName ||
                          selectedChat?.name ||
                          groupDetails?.groupInfo?.groupName
                        }
                        customStyle={{ fontWeight: 500 }}
                      />
                      {groupDetails && (
                        <StyledTypography
                          onClick={visibleInfoModal}
                          className="cursor-pointer"
                          sx={{ fontSize: "12px" }}>
                          {groupDetails?.groupMembersNumber}{" "}
                          {Translate("messagePage:members")}
                        </StyledTypography>
                      )}
                    </Stack>
                  </div>
                  <div className="chatHeaderOptions">
                    <div>
                      {type === GROUP_LIST && groupDetails?.addRemoveAccess && (
                        <span
                          data-toggle="tooltip"
                          title={Translate("group:addMemberToolTip")}>
                          <IconifyIcon
                            icon="mdi:user-plus-outline"
                            style={{ fontSize: "20px" }}
                            className="cursor-pointer mr-2"
                            onClick={visibleAddMemberModal}
                            hFlip
                          />
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="d-flex ml-2">
                        <div className="mt-1">
                          {((type === GROUP_LIST && groupFilters?.length > 0) ||
                            (type === CHAT_LIST &&
                              chatFilters?.length > 0)) && (
                            <a
                              href="/#"
                              className="nav-link dropdown-toggle "
                              data-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i
                                className="fa fa-ellipsis-v manageIconColor"
                                data-toggle="tooltip"
                                title={Translate("inboxPage:filter")}
                              />
                            </a>
                          )}
                          {type === GROUP_LIST ? (
                            <div className="dropdown-menu dropdownAlignment">
                              {groupFilters.map((filterItem, index) => (
                                <Fragment key={index}>
                                  <div
                                    onClick={() =>
                                      getFilterNavigate(filterItem)
                                    }>
                                    <a className="dropdown-item cursor-pointer">
                                      {Translate(`messagePage:${filterItem}`)}
                                    </a>
                                  </div>
                                  {groupFilters.length - 1 !== index && (
                                    <div className="dropdown-divider" />
                                  )}
                                </Fragment>
                              ))}
                            </div>
                          ) : (
                            <div className="dropdown-menu dropdownAlignment">
                              {chatFilters.map((filterItem, index) => (
                                <Fragment key={index}>
                                  <div
                                    onClick={() =>
                                      getFilterNavigate(filterItem)
                                    }>
                                    <a className="dropdown-item cursor-pointer">
                                      {Translate(`messagePage:${filterItem}`)}
                                    </a>
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="chat_windows" id="messageUlbody">
                {!messageDetail?.length && isLoading && (
                  <Stack height={"30vh"} justifyContent={"center"}>
                    <Loader loading={isLoading} isListLoader />
                  </Stack>
                )}
                {hasNextPage && !!messageDetail.length && (
                  <MessageListLoader
                    isLoading={isLoading}
                    onLoadMoreClick={onClickLoadMore}
                  />
                )}
                <ul
                  className="mb-0 pr-1"
                  style={{
                    height: hasNextPage
                      ? "calc(100vh - 275px)"
                      : "calc(100vh - 210px)",
                  }}>
                  {messageDetail?.map((message, idx) => {
                    return (
                      <Fragment key={idx}>
                        {(idx == 0 ||
                          getTime(message) !==
                            getTime(messageDetail[idx - 1])) && (
                          <span
                            className="time leftDirection"
                            style={{ textAlign: "center", display: "block" }}>
                            {getTime(message)}
                          </span>
                        )}
                        <MessageDetailItem
                          currentChat={currentChat}
                          currentMessage={message}
                          type={type}
                          isLastMessage={messageDetail?.length - 1 === idx}
                          onDeleteMessage={() =>
                            onDeleteMessage?.(message?._id)
                          }
                        />
                      </Fragment>
                    );
                  })}
                  <div ref={bottomRef} />
                </ul>
                {selectedChat &&
                  (messageDetail?.length ? true : !isLoading) && (
                    <div className="chat-message clearfix align-items-start">
                      <Stack
                        sx={{
                          position: "absolute",
                          bottom: "45px",
                          left: "10px",
                        }}>
                        <CommonImage
                          images={images}
                          files={filesAdded}
                          removeFile={removeFile}
                          containerStyle={{ background: colors.grey_008 }}
                        />
                      </Stack>
                      <div
                        className="input-group mb-0"
                        style={{ width: "90%", maxHeight: "47px" }}>
                        <MentionsInput
                          placeholder={Translate(
                            "group:typeSomethingPlaceholder"
                          )}
                          style={mentionInputstyle}
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.code === "Enter" /* && !e.shiftKey */) {
                              e.preventDefault();
                              sendMessage();
                            }
                          }}
                          className="form-control messageCustomInput">
                          <Mention
                            trigger={
                              type === GROUP_LIST
                                ? "@"
                                : "~`:;<.,./lhsundefined"
                            }
                            displayTransform={(...rest) => `@${rest[1]}`}
                            data={groupMembers}
                          />
                        </MentionsInput>
                      </div>
                      <a
                        className="cursor-pointer px-3"
                        style={{
                          paddingTop: "0.7rem",
                          paddingBottom: "0.6rem",
                          border: "1px solid silver",
                          borderLeft: "none",
                          width: "5%",
                        }}>
                        <Dropzone
                          icon={"icon-paper-clip font-18"}
                          iconTitle={"upload file"}
                          onfileSelect={addFiles}
                          acceptedImages={{
                            "image/png": [
                              ".png",
                              ".jpg",
                              ".jpeg",
                              ".pdf",
                              ".heic",
                              ".heif",
                              ".xls",
                              ".xlsx",
                            ],
                          }}
                          maxFileSize={52428800}
                          maximumFiles={1}
                          fileLength={filesAdded.length}
                          dropzoneStyle={{ transform: "rotate(-45deg)" }}
                        />
                      </a>
                      <a
                        className="cursor-pointer px-3"
                        style={{
                          opacity: value?.trim() || filesAdded.length ? 1 : 0.5,
                          background: colors.primary,
                          paddingTop: "0.7rem",
                          paddingBottom: "0.7rem",
                          width: "5%",
                        }}
                        onClick={sendMessage}>
                        <IconifyIcon
                          className="msgSendIcon"
                          icon={"mdi:send"}
                          fontSize="20"
                          style={{ color: colors.white }}
                        />
                      </a>
                    </div>
                  )}
              </div>
              {type === CHAT_LIST && (
                <ChatInfo
                  showModal={showInfoModal}
                  handleClose={hideInfoModal}
                  selectedUser={userInfo}
                  commonGroups={commonGroups}
                  onClickForSelectGroup={onClickForSelectGroup}
                />
              )}
            </div>
            {type === CHAT_LIST && (
              <ChatInfo
                showModal={showInfoModal}
                handleClose={hideInfoModal}
                selectedUser={userInfo}
                commonGroups={commonGroups}
                onClickForSelectGroup={onClickForSelectGroup}
              />
            )}
            {type === GROUP_LIST && showInfoModal && (
              <GroupInfo
                showModal={showInfoModal}
                handleClose={hideInfoModal}
                groupId={selectedChat?._id}
                selectedUser={userInfo}
                headerData={groupDetails}
                onClickForRemoveMember={handleAddAndRemoveMember}
                setShowModal={setShowInfoModal}
                hasRemoveAccess={groupDetails?.addRemoveAccess}
                onGroupIconUpdate={onGroupIconUpdate}
                onGroupIconDelete={onGroupIconDelete}
                onMessageFromGroup={onMessageFromGroup}
              />
            )}

            {showAddMember && (
              <AddGroupMember
                showModal={showAddMember}
                handleClose={hideAddMemberModal}
                groupId={selectedChat?._id}
                currentGroupMembers={groupDetails.record}
                onClickForAddMember={handleAddAndRemoveMember}
                setShowAddMember={setShowAddMember}
              />
            )}
          </div>
        </div>
      </div>
      {showEditModal && (
        <GroupNameEdit
          handleModal={setShowEditModal}
          showModal={showEditModal}
          groupId={selectedChat?._id}
          existingGroupName={groupDetails?.groupInfo?.groupName}
          groupDetails={groupDetails && groupDetails}
          onSuccessFullEdit={onSuccessFullEdit}
        />
      )}
    </>
  );
};
export default MessageDetail;
