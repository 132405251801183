// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  selectCompanyAction,
  setFcmTokenAction,
  setNotificationBody,
  setNotificationLogout,
  userDataAction,
  userTypeAction,
} from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import { baseQuery } from "../CreateApi";

// Define a service using a base URL and expected endpoints

const baseQueryV105 = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL_V105,
  // baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.formanagement?.userData?.accessToken;
    const timezone = getState()?.formanagement?.userData?.login?.timezone;
    // let encryptedTimezone = EncryptData({ reqObj: timezone });
    // const token = "token";
    if (token && timezone) {
      headers.append("token", token);
      headers.append("Content-Type", "application/json");
      // headers.append("timezone", encryptedTimezone);
      headers.append("timezone", timezone);
    }
    return headers;
  },
  // credentials: "include",
});

// const errorStatusArray = [
//   "Task is rejected, can not perform operation",
//   "Task is accepted, can not perform operation",
//   "Task is inprogress, can not perform operation",
//   "Task is awaitingapproval, can not perform operation",
//   "Task is completed, can not perform operation",
//   "Task is reopened, can not perform operation",
//   "Task name is invalid!",
// ];
// var crypto = require("crypto-js");

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result;
  if (api.getState().formanagement.notificationLogout != true) {
    var platform = require("platform");

    //Encryption for the request body
    // let encryptedArgs = args;
    // if (encryptedArgs?.body) {
    //   const hasWebAttachment =
    //     encryptedArgs?.body.hasOwnProperty("webAttachment");
    //   const hasWebProfilurl = encryptedArgs?.body.hasOwnProperty("webProfileUrl");
    //   const haswebProfileImage =
    //     encryptedArgs?.body.hasOwnProperty("webProfileImage");
    //   const webLogo = encryptedArgs?.body.hasOwnProperty("webLogo");
    //   const haswebGroupImage =
    //     encryptedArgs?.body.hasOwnProperty("webGroupImage");
    //   const attachment = encryptedArgs?.body.hasOwnProperty("attachment");
    //   const isComments = encryptedArgs?.body.hasOwnProperty("comment");
    //   const isTask = encryptedArgs?.body.hasOwnProperty("taskNumber");

    //   if (attachment && isComments) {
    //     const attachmentData = encryptedArgs?.body.attachment
    //       ? encryptedArgs.body.attachment
    //       : "";

    //     const attachmentData2 = encryptedArgs?.body.attachment
    //       ? encryptedArgs.body.attachment?.hasOwnProperty("url")
    //       : false;

    //     if (attachmentData.length > 0 && !attachmentData2) {
    //       let attachmentKey, attachmentValue;
    //       let encryptedDataWithoutattachments;
    //       attachmentKey = "attachment";
    //       attachmentValue = encryptedArgs?.body?.attachment;
    //       const { attachment, ...bodyWithoutWebAttachment } = encryptedArgs.body;
    //       encryptedDataWithoutattachments = bodyWithoutWebAttachment;
    //       const encryptedData = EncryptData({
    //         reqObj: encryptedDataWithoutattachments,
    //       });
    //       const attachmentObj = { [attachmentKey]: attachmentValue };

    //       encryptedArgs.body = { encryptedData, attachmentObj };
    //     }
    //     if (attachmentData === "" && !attachmentData2) {
    //       const encryptedData = EncryptData({ reqObj: encryptedArgs.body });

    //       encryptedArgs.body = { encryptedData };
    //     }

    //     if (attachmentData2) {
    //       const encryptedData = EncryptData({ reqObj: encryptedArgs.body });

    //       encryptedArgs.body = { encryptedData };
    //     }
    //   } else {
    //     if (hasWebAttachment || hasWebProfilurl || webLogo) {
    //       let attachmentKey, attachmentValue;
    //       let encryptedDataWithoutattachments;
    //       if (hasWebAttachment && !isTask) {
    //         attachmentKey = "webAttachment";
    //         attachmentValue = encryptedArgs?.body?.webAttachment;
    //         const { webAttachment, ...bodyWithoutWebAttachment } =
    //           encryptedArgs.body;

    //         encryptedDataWithoutattachments = bodyWithoutWebAttachment;
    //       } else if (hasWebAttachment && isTask) {
    //         attachmentKey = "webAttachment";
    //         attachmentValue = encryptedArgs?.body?.webAttachment;
    //         const { webAttachment, ...bodyWithoutWebAttachment } =
    //           encryptedArgs.body;

    //         encryptedDataWithoutattachments = bodyWithoutWebAttachment;
    //       } else if (webLogo) {
    //         attachmentKey = "webLogo";
    //         attachmentValue = encryptedArgs?.body?.webLogo;
    //         const { webLogo, ...bodyWithoutWebAttachment } = encryptedArgs.body;

    //         encryptedDataWithoutattachments = bodyWithoutWebAttachment;
    //       } else {
    //         attachmentKey = "webProfileUrl";
    //         attachmentValue = encryptedArgs?.body?.webProfileUrl;
    //         const { webProfileUrl, ...bodyWithoutWebAttachment } =
    //           encryptedArgs.body;

    //         encryptedDataWithoutattachments = bodyWithoutWebAttachment;
    //       }

    //       const encryptedData = EncryptData({
    //         reqObj: encryptedDataWithoutattachments,
    //       });
    //       const attachmentObj = { [attachmentKey]: attachmentValue };

    //       encryptedArgs.body = { encryptedData, attachmentObj };
    //     } else if (haswebProfileImage || haswebGroupImage) {
    //       let attachmentKey, attachmentValue;
    //       if (haswebProfileImage) {
    //         attachmentKey = "webProfileImage";
    //         attachmentValue = encryptedArgs?.body?.webProfileImage;
    //       } else {
    //         attachmentKey = "webGroupImage";
    //         attachmentValue = encryptedArgs?.body?.webGroupImage;
    //       }

    //       const attachmentObj = { [attachmentKey]: attachmentValue };

    //       encryptedArgs.body = { attachmentObj };
    //     } else {
    //       const encryptedData = EncryptData({ reqObj: encryptedArgs.body });

    //       encryptedArgs.body = { encryptedData };
    //     }
    //   }
    // }

    // let result = await baseQuery(encryptedArgs, api, extraOptions);
    result = await baseQueryV105(args, api, extraOptions);

    if (result?.error && result.error.status === 401) {
      if (result.error.data.error.name === "TokenExpiredError") {
        // send refresh token to get new access token
        const refreshResult = await baseQuery(
          {
            url: Urls.refreshToken,
            method: Methods.post,
            body: {
              token: api.getState().formanagement.userData?.refreshToken,
              // token: "token",
              loginFor: "WEB",
              device: {
                deviceId: "6797279a44145cf2",
                osType: "WEB",
                osVersion: platform.parse(platform.ua).version || "",
                deviceUniqueId: "",
                deviceBrand: platform.name || "",
                deviceModel: "",
                deviceType: platform.product || "",
                appVersion: platform.version || "",
                fcmToken: api.getState().formanagement.fcmToken,
              },
            },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          // const user = api.getState().formanagement.userData;
          // store the new token
          // AsyncStorage.setItem(
          //   STR_KEYS.LOGINUSERDATA,
          //   JSON.stringify({
          //     ...user,
          //     accessToken: refreshResult?.data.data.token,
          //     refreshToken: refreshResult?.data.data.refreshToken,
          //   }),
          // );
          api.dispatch(userDataAction(refreshResult?.data));
          localStorage.setItem("userData", JSON.stringify(refreshResult?.data));

          // api.dispatch(
          //   userDataAction({
          //     ...user,
          //     accessToken: refreshResult?.data.data.token,
          //     refreshToken: refreshResult?.data.data.refreshToken,
          //   }),
          // );
          // retry the original query with new access token
          result = await baseQueryV105(args, api, extraOptions);
        }
      } else {
        // await AsyncStorage.removeItem('UserType');
        api.dispatch(userTypeAction(undefined));
        api.dispatch(userDataAction(undefined));
        localStorage.removeItem("userData");
        api.dispatch(setFcmTokenAction(""));
        api.dispatch(selectCompanyAction([]));
        api.dispatch(setNotificationLogout(false));
        api.dispatch(setNotificationBody(undefined));
        // await AsyncStorage.getItem('UserType');
        // AsyncStorage.setItem(STR_KEYS.LOGINUSERDATA, '');
        // api.dispatch(userDataAction(undefined));
        // await AsyncStorage.removeItem(STR_KEYS.USERTOKEN);
        // await AsyncStorage.removeItem(STR_KEYS.USERTYPE);
        // showToast(result.error?.data.error.message);
        // api.dispatch(logOut());
      }
    } else if (result.error && result.error.originalStatus === 500) {
      showToast({
        type: "error",
        message: Translate("common:unknownServerError"),
      });
    } else if (result.error && result.error.status === "FETCH_ERROR") {
    } else if (result.error) {
    }
    // if (result.data?.data) {
    //   const encryptionKey = crypto.enc.Base64.parse(
    //     process.env.REACT_APP_ENCRYPTION_KEY
    //   );
    //   const iv = crypto.enc.Base64.parse(process.env.REACT_APP_IV);
    //   const decryptData = crypto.AES.decrypt(result.data.data, encryptionKey, {
    //     iv: iv,
    //   });
    //   result.data.data = JSON.parse(decryptData.toString(crypto.enc.Utf8));
    // }
  }
  return result;
};

export const CreateApiV105 = createApi({
  reducerPath: "CreateApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ["profile"],
});
