import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ReactComponent as Chat } from "../../../src/assets/images/drawer-menu-svg/message.svg";
import { ReactComponent as BrandLogo } from "../../assets/images/LogoApp_FOR.svg";
import { toggleLeftMenuAction } from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import NotificationAlertPermission from "../NotificationAlert";
import RoutesPath from "../Route";
import Header from "./Header";
import SidebarDrawer from "./Sidebar";
import { UnAuth } from "./UnAuthorised";
import usePrevLocation from "./UsePrevLocation";

const AuthRoutes = (props) => {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const dispatch = useDispatch();
  const [showNotificationPopup, setShowNotificationPermission] =
    useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const pageHeading = RoutesPath.filter(
    (route) => route.path === location.pathname
  );
  const [parentlink, setParentLink] = useState(null);

  const { isMinSidebar, isToggleLeftMenu, userData } = useSelector(
    (state) => state.formanagement
  );
  const masterNone = {
    display: "none",
  };

  const masterBlock = {
    display: "block",
  };

  useEffect(() => {
    if (Notification.permission !== "granted") {
      setShowNotificationPermission(true);
    }
  }, []);
  useEffect(() => {
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];
    if (prevLocation.pathname !== location.pathname) {
      if (parentlink && nochildlink && nochildlink === "dashboard") {
        handler(parentlink, `${parentlink}${nochildlink}`);
      } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
        handler(parentlink, nochildlink);
      } else if (parentlink) {
        handler(parentlink, "");
      } else {
        handler("hr", "dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handler = (parentlink, nochildlink) => {
    setParentLink(parentlink);
  };
  const toggleLeftMenu = (e) => {
    dispatch(toggleLeftMenuAction(e));
  };
  window.addEventListener("scroll", function () {
    const maxHeight = document.body.scrollHeight - window.innerHeight;
    setScrollHeight(Math.round((window.pageYOffset * 100) / maxHeight));
  });
  return (
    <>
      <div className={`${isToggleLeftMenu ? "offcanvas-active" : ""}`}>
        {showNotificationPopup && (
          <NotificationAlertPermission
            subTitle={Translate("common:notificationAlert")}
            link={
              "https://support.google.com/chrome/answer/3220216?sjid=7679138954814274276-AP"
            }
            onCanel={() => setShowNotificationPermission(false)}
          />
        )}
        {location.pathname !== "/taskdependancy" && (
          <div style={parentlink === "login" ? masterNone : masterBlock}>
            <div
              id="header_top"
              className={`header_top ${isMinSidebar && "dark"}`}
              style={{
                top: showNotificationPopup ? 52 : 0,
              }}>
              <div className="container">
                <div
                  className="hleft"
                  style={{ display: "flex", flexDirection: "column" }}>
                  <NavLink
                    to="/"
                    onClick={() => this.handler("hr", "dashboard")}
                    className="header-brand">
                    <BrandLogo className="for-brand-logo" />
                  </NavLink>
                  {userData?.menuAccess.slice(0, 3)?.map((item) => {
                    const menu = Object.values(item)[0];
                    const { parent, isLeftMenu, isEnable } = menu;
                    return (
                      isEnable &&
                      isLeftMenu && (
                        <NavLink
                          to={parent?.navigation}
                          className="header-brand">
                          {parent?.navigation == "/message" ? (
                            <Chat
                              fill={
                                location?.pathname.includes("/message")
                                  ? "white"
                                  : "#02005B"
                              }
                              className={`${parent?.icon} ${
                                location.pathname.includes("/message")
                                  ? "active-icon"
                                  : ""
                              }`}
                              title={Translate(`common:${parent?.label}`)}
                            />
                          ) : (
                            (parent?.navigation == "/inbox/inboxList" ||
                              parent?.navigation == "/calendar") && (
                              <i
                                className={`${parent?.icon} ${
                                  (
                                    parent?.navigation?.includes("/inbox")
                                      ? location.pathname.includes("/inbox")
                                      : location.pathname.includes("/calendar")
                                  )
                                    ? "active-icon"
                                    : ""
                                }`}
                                style={{ fontSize: "19px" }}
                                title={Translate(`common:${parent?.label}`)}
                              />
                            )
                          )}
                        </NavLink>
                      )
                    );
                  })}
                </div>
                <div className="hright">
                  <div className="dropdown">
                    <p
                      className="nav-link icon menu_toggle"
                      onClick={() => toggleLeftMenu(!isToggleLeftMenu)}>
                      <i className="fa  fa-align-left" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="left-sidebar"
              className="sidebar "
              style={{
                top: showNotificationPopup ? 52 : 0,
              }}>
              <h4 className="brand-name">
                {Translate("common:forManagemnet")}
              </h4>
              <nav id="left-sidebar-nav" className="sidebar-nav">
                <SidebarDrawer />
              </nav>
            </div>
          </div>
        )}
        <div
          className={
            location.pathname === "/taskdependancy" ? "webViewPage" : "page"
          }>
          {location.pathname !== "/taskdependancy" && (
            <Header
              dataFromParent={props.dataFromParent}
              dataFromSubParent={pageHeading[0]?.pageTitle}
            />
          )}
          {props?.isAuthorised ? (
            <Routes>
              {RoutesPath.map((layout) => {
                return (
                  <Route
                    path={layout.path}
                    element={<layout.component />}
                    key={layout.name}
                  />
                );
              })}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <UnAuth />
          )}
        </div>
      </div>
    </>
  );
};
export default AuthRoutes;
